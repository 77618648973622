import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaStar } from 'react-icons/fa';
import { FaSpinner } from 'react-icons/fa'; // Import spinner icon
import { renderStars, getScoreEmoji } from '../utils/helpers';
import { getRecommendations, addToList as apiAddToList, getMyList } from '../utils/api';
import GridListView from './GridListView';
import './MyReco.css';

function MyReco() {
  const navigate = useNavigate();
  const [recommendations, setRecommendations] = useState([]);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showScoringModal, setShowScoringModal] = useState(false);
  const [loading, setLoading] = useState(true); // Add loading state
  const [userList, setUserList] = useState([]);
  const fetchListStatus = async () => {
    try {
      const response = await getMyList();
      setUserList(response.data);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        fetchInitialData();
        fetchListStatus();  // Add initial list fetch
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();
  }, [navigate]);
  
  const isInUserList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };
  
  const fetchInitialData = async () => {
    try {
      const recoResponse = await getRecommendations();
      setRecommendations(recoResponse);
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setLoading(false); // Set loading to false in case of error
    }
  };

  const getImageUrl = (posterPath) => {
    if (posterPath) {
      return `https://image.tmdb.org/t/p/w500${posterPath}`;
    }
    return 'https://via.placeholder.com/500x750/1a1a1a/61dafb?text=No+Poster';
  };

  const handleMovieClick = (index) => {
    setCurrentIndex(index);
    setFullscreenMode(true);
  };

  const handleScroll = (direction) => {
    if (direction === 'up' && currentIndex < recommendations.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else if (direction === 'down' && currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      // Rafraîchir les recommendations après le scoring
      await Promise.all([
        fetchInitialData(),
        fetchListStatus()
      ]);
      setShowScoringModal(false);
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
    try {
      await apiAddToList(filmId, mediaType, title, posterPath, genreIds);
      fetchListStatus(); // Update list status after adding
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  return (
    <div className="my-recommendations">
      <h2>Recommendations</h2>
      {loading ? ( // Show animated spinner while loading
        <div className="loading-spinner">
          <FaSpinner className="spinner-icon" />
        </div>
      ) : (
        recommendations.length > 0 ? (
          <>

              <GridListView 
              items={recommendations}
              currentIndex={currentIndex}
              isInList={isInUserList}
              addToList={addToList}
              handleScoring={handleScoring}
              onListUpdate={() => {
                Promise.all([
                  fetchInitialData(),
                  fetchListStatus()
                ]);
              }}
              getImageUrl={getImageUrl}  // Passer la fonction getImageUrl comme prop
              />  


          </>
        ) : (
          <p>No recommendations available</p>
        )
      )}
    </div>
  );
}

export default MyReco;
