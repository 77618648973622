import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import GridListView from './GridListView';
import { getScoreEmoji } from '../utils/helpers';
import { getMyList, addToList as apiAddToList, removeFromList as apiRemoveFromList } from '../utils/api';
import './MyList.css';

function MyList() {
  const navigate = useNavigate();
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        fetchMyList();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();
  }, [navigate]);

  const fetchMyList = async () => {
    try {
      const response = await getMyList();
      setMyList(response.data);
    } catch (error) {
      console.error('There was an error fetching the list!', error);
    } finally {
      setLoading(false);
    }
  };

  const isInList = (filmId) => {
    return myList.some(item => item.film_id === filmId);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      
      fetchMyList(); // Refresh the list after rating
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
    try {
      await apiAddToList(filmId, mediaType, title, posterPath, genreIds);
      fetchMyList();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  const removeFromList = async (filmId) => {
    try {
      await apiRemoveFromList(filmId);
      setMyList(prevList => prevList.filter(item => item.film_id !== filmId));
    } catch (error) {
      console.error('Error removing from list:', error);
    }
  };

  return (
    <div className="my-list">
      <h2>My List</h2>
      {loading ? (
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
        </div>
      ) : (
        myList.length > 0 ? (
          <GridListView 
            items={myList}
            isInList={isInList}
            addToList={addToList}
            handleScoring={handleScoring}
            onListUpdate={fetchMyList}
          />
        ) : (
          <p>Your list is empty</p>
        )
      )}
    </div>
  );
}

export default MyList;