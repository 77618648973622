import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilm, faWandMagicSparkles } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import GridListView from './GridListView';
import { getScoreEmoji, isInList } from '../utils/helpers';
import './Search.css';
import { getMyList, askForMovieTitle } from '../utils/api';

function Search({ addToList }) {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showScoringModal, setShowScoringModal] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [error, setError] = useState('');
  const [userList, setUserList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isPersonSearch, setIsPersonSearch] = useState(false);
  const [description, setDescription] = useState('');
  const [titleSuggestions, setTitleSuggestions] = useState(null);
  const [isSearchingTitle, setIsSearchingTitle] = useState(false);
  const [searchStep, setSearchStep] = useState('');
  const [activeTab, setActiveTab] = useState('title'); // Add this line
  const [suggestion, setSuggestion] = useState('');

  const fetchListStatus = async () => {
    try {
      // const response = await getMyList();
      // setUserList(response.data);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const isInUserList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };

  useEffect(() => {
    fetchListStatus();
  }, []);

  const handleScroll = (direction) => {
    if (direction === 'up' && currentIndex < results.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else if (direction === 'down' && currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const handleMovieClick = async (index) => {
    setCurrentIndex(index);
    setFullscreenMode(true);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      performSearch(query); // Refresh results after rating
      fetchListStatus(); // Refresh list status after rating
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const getImageUrl = (posterPath) => {
    if (posterPath) {
      return `https://image.tmdb.org/t/p/w500${posterPath}`;
    }
    return 'https://via.placeholder.com/500x750/1a1a1a/61dafb?text=No+Poster';
  };

  const performSearch = useCallback((searchQuery) => {
    if (searchQuery.length > 0 || new URLSearchParams(window.location.search).get('query_person')) {
      setIsLoading(true);
      const searchParams = new URLSearchParams(window.location.search);
      const personId = searchParams.get('query_person');
      
      const endpoint = personId 
        ? `/api/search?query_person=${personId}`
        : `/api/search?query=${searchQuery}`;

      axios.get(endpoint)
        .then(response => {
          setResults(response.data);
          setError('');
        })
        .catch(error => {
          console.error('There was an error searching!', error);
          setError('An error occurred while searching. Please try again later.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setResults([]);
      setError('');
    }
  }, []);

  useEffect(() => {
    // Ne lancer la recherche automatique que pour la barre de recherche principale,
    // pas pour la recherche par description
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    if (query.trim()) {  // Seulement si query n'est pas vide
      const timeoutId = setTimeout(() => {
        performSearch(query);
      }, 600);

      setTypingTimeout(timeoutId);
    }

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [query, performSearch]);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const personId = searchParams.get('query_person');
    setIsPersonSearch(!!personId);
    if (personId) {
      performSearch('');
    }
  }, []);

  const handleDescriptionSearch = async () => {
    if (!description.trim()) return;
    
    setIsLoading(true);
    setError('');
    setSuggestion('');
    
    try {
      const response = await askForMovieTitle(description.trim());
      if (response && response.result) {
        setSuggestion(response.result);
        // Auto-search the first suggested title
        const firstTitle = response.result.split('\n')[0];
        if (firstTitle) {
          setQuery(firstTitle);
          performSearch(firstTitle);
        }
      } else {
        setError('Aucune suggestion trouvée. Essayez une description différente.');
      }
    } catch (error) {
      setError(error.message || 'Erreur lors de la recherche. Veuillez réessayer.');
      console.error('Error getting suggestions:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="search-page">
      
      <div className="profile-nav-buttons">
            <button className="nav-button" onClick={() => navigate('/my-list')}>
              <FontAwesomeIcon icon={faFilm} />
              <span>My List</span>
            </button>
            <button className="nav-button" onClick={() => navigate('/recommendations')}>
              <FontAwesomeIcon icon={faWandMagicSparkles} />
              <span>My Reco</span>
            </button>
      </div>
      <h2>Search Movies and TV Shows</h2>

      <div className="search-tabs">
        <button 
          className={`tab-button ${activeTab === 'title' ? 'active' : ''}`}
          onClick={() => setActiveTab('title')}
          data-tab="title"
        >
          Search by Title
        </button>
        <button 
          className={`tab-button ${activeTab === 'description' ? 'active' : ''}`}
          onClick={() => setActiveTab('description')}
          data-tab="description"
        >
          Search by Description
        </button>
      </div>

      {activeTab === 'title' && (
        <>
          <div className="search-container">
            {isPersonSearch ? (
              <button 
                className="back-button" 
                onClick={() => window.location.href = '/search'}
              >
                Remove People Filter
              </button>
            ) : (
              <div className="search-input-wrapper">
                <input
                  type="text"
                  placeholder="Search..."
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
                {query && (
                  <button 
                    className="clear-search" 
                    onClick={() => setQuery('')}
                  >
                    ✕
                  </button>
                )}
              </div>
            )}
            {isLoading && <div className="loading-spinner-search"></div>}
          </div>
          {error && <p className="error-message">{error}</p>}
          <GridListView 
            items={results}
            currentIndex={currentIndex}
            isInList={isInUserList}
            addToList={addToList}
            handleScoring={handleScoring}
            onListUpdate={() => {
              performSearch(query);
              fetchListStatus();
            }}
          />
        </>
      )}

      {activeTab === 'description' && (
        <>
          <div className="search-container description-search">
            <div className="description-input-group">
              <textarea
                placeholder="Enter a movie or TV show description..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                rows={4}
              />
              <button 
                onClick={handleDescriptionSearch}
                disabled={isLoading || !description.trim()}
                className={isLoading ? 'loading' : ''}
              >
                {isLoading ? 'Searching...' : 'Search'}
              </button>
            </div>

            {isLoading && (
              <div className="searching-status">
                <div className="loading-spinner-search"></div>
                <p>Analysis...</p>
              </div>
            )}

            {suggestion && (
              <div className="suggestion-results">
                <h4>AI Suggestion:</h4>
                <div className="suggestion-content">
                  {suggestion.split('\n').map((line, index) => (
                    <div key={index} className="suggestion-item">
                      {line}
                    </div>
                  ))}
                </div>
              </div>
            )}

            {error && <p className="error-message">{error}</p>}
          </div>
          {error && <p className="error-message">{error}</p>}
          {results.length > 0 && (
            <GridListView 
              items={results}
              currentIndex={currentIndex}
              isInList={isInUserList}
              addToList={addToList}
              handleScoring={handleScoring}
              onListUpdate={() => {
                performSearch(query);
                fetchListStatus();
              }}
            />
          )}
        </>
      )}
    </div>
  );
}

export default Search;