import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { register as apiRegister } from '../utils/api';
import './Register.css';

function Register() {
  const navigate = useNavigate();
  const { register } = useAuth();
  const [formData, setFormData] = useState({
    email: '',
    firstname: '',
    name: '',
    password: '',
    confirmPassword: '',
    acceptCGV: false,
    captchaToken: ''
  });
  const [error, setError] = useState('');
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const turnstileRef = useRef(null);

  useEffect(() => {
    // Définir la fonction callback pour le captcha
    window.enableSubmitButton = (token) => {
      setIsCaptchaVerified(true);
      setFormData(prev => ({
        ...prev,
        captchaToken: token
      }));
    };
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setError('');

    if (formData.password !== formData.confirmPassword) {
      setError("Passwords don't match");
      return;
    }

    try {
      const userData = {
        email: formData.email,
        firstname: formData.firstname,
        name: formData.name,
        password: formData.password,
        acceptCGV: formData.acceptCGV
        // captchaToken temporairement retiré
      };

       await apiRegister(formData);

        navigate('/login', { replace: true });

    } catch (error) {
        console.error('Error registering:', error);
      setError(error.response?.data?.error || 'An error occurred during registration');
    }
  };

  return (
    <div className="register-container">
      <div className="register-box">
        <h1 className="register-title">Create Account</h1>
        {error && (
          <div className="error-alert">
            <i className="fas fa-exclamation-circle"></i>
            {error}
          </div>
        )}
        <form onSubmit={handleRegister} className="register-form">
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-envelope"></i>
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-user"></i>
              <input
                type="text"
                name="firstname"
                placeholder="First Name"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-user"></i>
              <input
                type="text"
                name="name"
                placeholder="Last Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-lock"></i>
              <input
                type="password"
                name="password"
                placeholder="Password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group">
            <div className="input-group">
              <i className="fas fa-lock"></i>
              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirm Password"
                value={formData.confirmPassword}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="form-group checkbox-group">
            <label>
              <input
                type="checkbox"
                name="acceptCGV"
                checked={formData.acceptCGV}
                onChange={handleChange}
                required
              />
              I accept the terms and conditions
            </label>
          </div>
          <div className="form-group captcha-group">
            <div 
              ref={turnstileRef}
              className="cf-turnstile" 
              data-sitekey="0x4AAAAAAAzLlEy1_lXu48vd" 
              data-callback="enableSubmitButton"
            ></div>
          </div>
          <button 
            type="submit" 
            className="register-button"
            // disabled temporairement retiré
          >
            <span>Register</span>
            <i className="fas fa-arrow-right"></i>
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;