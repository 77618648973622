import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getScoreEmojiOnly, renderStars } from '../utils/helpers';
import BlurayComponent from './BlurayComponent';

const FeedItem = styled.div`
  display: flex;
  padding: 0px 3px 0px 0px;
  width: 96vw;
  left: 2vw;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  height: 120px;
  position: relative;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.15);
  }
`;

const UserInfo = styled.div`
  position: absolute;
  top: 20px;
  right: 3px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

`;

const Avatar = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 50%;
  background: 
    linear-gradient(45deg, rgba(0,102,204,1), rgba(0,204,255,0.5));
  border: 3px solid transparent;
  `;


  
const Username = styled.span`
  font-weight: 700;
  color: #666;
`;
const Date = styled.span`
  font-weight: 500;
  color: #fff;
  width: 100%;
  text-align: left;
  display: block;
  margin: 10px 6px;
`;

const RatingSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20%;
`;

const RatingEmoji = styled.span`
  font-size: 24px;
  margin-left: 0px;  // Aligner avec l'avatar + username
`;

const Comments = styled.span`
  font-size: 0.9em;
  color: #666;
  max-height: 50px;
  max-width: 100%;
  margin-left: 0px;  // Aligner avec l'avatar + username
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  display: -moz-box;
  display: box;
  -webkit-line-clamp: 2;
  -moz-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
`;

const MoviePoster = styled.img`
  width: 80px;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
`;

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  padding: 8px 0;
  width: calc(100% - 180px); // Account for poster and user info
  max-width: calc(100% - 180px);
  overflow: hidden;
`;

const MovieTitle = styled.span`
  font-weight: 600;
  color: #333;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
`;

const MovieYear = styled.span`
  color: #666;
  font-size: 0.9em;
`;

const ExpandableContainer = styled.div`
  cursor: pointer;
  margin-bottom: 10px;
  padding: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  with: 100%;
  height: 100%;
  

`;

const BlurayWrapper = styled.div`
  position: relative;
  width: 100vw;
  left: 0vw;
  height: auto;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
  border-radius: 12px;
  margin-left: v0w;
  padding: 0;
`;

const FeedLineItem = ({ item, userAvatar, username, rating, moviePoster, movieTitle, date, comments }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const navigate = useNavigate();

  const handleClick = () => {
    setIsExpanded(!isExpanded);
  };

  const handleUserClick = (e) => {
    e.stopPropagation();
    navigate(`/user/${item.user_id}`);
  };

  return (
    <ExpandableContainer>
      {date && <Date>{date}</Date>}
      <FeedItem onClick={handleClick}>
        <MoviePoster src={moviePoster} alt="Movie Poster" />
        <UserInfo>
          <Username onClick={handleUserClick}>{username}</Username>
          <Avatar src={userAvatar} alt="User Avatar" onClick={handleUserClick} />
        </UserInfo>
        <ContentSection>
          <MovieTitle>{movieTitle}</MovieTitle>
          <RatingEmoji>{renderStars(rating)}</RatingEmoji>
          <Comments>{getScoreEmojiOnly(rating)} {comments}</Comments>
        </ContentSection>
      </FeedItem>
      <BlurayWrapper isExpanded={isExpanded}>
        {isExpanded && <BlurayComponent showScorings={true} showButtons={true} item={item} />}
      </BlurayWrapper>
    </ExpandableContainer>
  );
};

export default FeedLineItem;