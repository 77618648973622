import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FaStar, FaHandshake } from 'react-icons/fa';
import GridListView from './GridListView';
import { getAllUserScoring, getMyList, getCompatibility, rateMovie } from '../utils/api';
import axios from 'axios';
import './MyProfil.css';

function UserProfile() {
  const { userId } = useParams();
  const [userData, setUserData] = useState(null);
  const [userScoring, setUserScoring] = useState([]);
  const [userList, setUserList] = useState([]);
  const [compatibility, setCompatibility] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [profileResponse, scoringData, listResponse, compatibilityScore] = await Promise.all([
          axios.get(`/api/get-profile?user_id=${userId}`),
          getAllUserScoring(userId),
          getMyList(),
          getCompatibility(userId)
        ]);

        setUserData(profileResponse.data);
        setUserScoring(scoringData);
        setUserList(listResponse.data || []);
        setCompatibility(compatibilityScore);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [userId]);

  const isInList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await rateMovie(filmId, mediaType, score, comments);
      const scoringData = await getAllUserScoring(userId);
      setUserScoring(scoringData);
    } catch (error) {
      console.error('Error scoring movie:', error);
    }
  };

  const handleListUpdate = async () => {
    try {
      const [updatedScoring, updatedList] = await Promise.all([
        getAllUserScoring(userId),
        getMyList()
      ]);
      setUserScoring(updatedScoring);
      setUserList(updatedList.data || []);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  if (!userData) return <p>Loading profile...</p>;

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img 
          src={userData.avatar_url || 'https://via.placeholder.com/150'} 
          alt="Profile" 
          className="profile-avatar"
        />
        <h2>{userData.pseudo}</h2>
        {compatibility !== null && (
          <div className="compatibility-indicator">
            <FaHandshake className="compatibility-icon" />
            <span className="compatibility-label">Compatibilité</span>
            <span 
              className="compatibility-score"
              style={{
                color: compatibility > 70 ? '#4CAF50' : 
                      compatibility > 40 ? '#FFA726' : '#F44336'
              }}
            >
              {compatibility}%
            </span>
          </div>
        )}
      </div>
      
      <div className="profile-stats">
        <div className="stat-item">
          <span className="stat-value">{userData.rated_movie_count}</span>
          <span className="stat-label">Movies Rated</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">{userData.rated_tv_count}</span>
          <span className="stat-label">TV Shows Rated</span>
        </div>
      </div>

      <div className="profile-bio">
        <h3>About</h3>
        <p>{userData.bio || 'No bio yet'}</p>
      </div>

      <div className="user-ratings-section">
        <h3><FaStar /> Films & Séries Notés</h3>
        {userScoring.length > 0 ? (
          <GridListView
            items={userScoring}
            isInList={isInList}
            handleScoring={handleScoring}
            onListUpdate={handleListUpdate}
            isReadOnly={false}  // Changed to false to enable buttons
          />
        ) : (
          <p>Aucun film ou série noté pour le moment</p>
        )}
      </div>
    </div>
  );
}

export default UserProfile;