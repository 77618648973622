import React from 'react';
import ReactDOM from 'react-dom';

function Modal({ isOpen, onClose, children, title, showCloseButton = true }) { // Ajout du prop avec valeur par défaut
  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <button className="modal-close" onClick={onClose}>×</button>
        {title && <span className="modal-title">{title}</span>}
        {children}
        {showCloseButton && (
          <button className="close-modal-button" onClick={onClose}>
            Close
          </button>
        )}
      </div>
    </div>,
    document.body
  );
}

export default Modal;