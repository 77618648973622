// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.loading-icon {
  font-size: 5rem;
  animation: spin 1s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.my-list {
    min-height: 100vh;
    padding: 20px;
    background-color: #111;
    color: white;
    padding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */
  }

  .my-list  {
    position: relative;
    min-height: 100vh;
    padding-top: 10px;
    padding-bottom: 100px;
    background-color: #111;
    color: white;
    padding-bottom: 100px; /* Augmenté pour éviter le chevauchement avec la barre de menu */
  }

.filters-container {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.filter {
  flex: 1 1;
  min-width: 200px;
}

.filter label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: bold;
}

`, "",{"version":3,"sources":["webpack://./src/components/MyList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kCAAkC;EAClC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,oBAAoB,EAAE,gEAAgE;EACxF;;EAEA;IACE,kBAAkB;IAClB,iBAAiB;IACjB,iBAAiB;IACjB,qBAAqB;IACrB,sBAAsB;IACtB,YAAY;IACZ,qBAAqB,EAAE,gEAAgE;EACzF;;AAEF;EACE,aAAa;EACb,SAAS;EACT,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,iBAAiB;AACnB","sourcesContent":[".loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50vh;\n}\n\n.loading-icon {\n  font-size: 5rem;\n  animation: spin 1s linear infinite;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 50vh;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.my-list {\n    min-height: 100vh;\n    padding: 20px;\n    background-color: #111;\n    color: white;\n    padding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */\n  }\n\n  .my-list  {\n    position: relative;\n    min-height: 100vh;\n    padding-top: 10px;\n    padding-bottom: 100px;\n    background-color: #111;\n    color: white;\n    padding-bottom: 100px; /* Augmenté pour éviter le chevauchement avec la barre de menu */\n  }\n\n.filters-container {\n  display: flex;\n  gap: 1rem;\n  margin-bottom: 1rem;\n  flex-wrap: wrap;\n}\n\n.filter {\n  flex: 1;\n  min-width: 200px;\n}\n\n.filter label {\n  display: block;\n  margin-bottom: 0.5rem;\n  font-weight: bold;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
