import React, { useState, useEffect, useRef } from 'react';
import { getScoreEmoji, renderStars, getScoreEmojiOnly } from '../utils/helpers';
import './Notation.css';

const Notation = ({ onChange, onRelease, onSubmit, compact, showTooltip, initialRating }) => {
  const [score, setScore] = useState(initialRating || 0); // Changé de 1 à 0
  const [displayScore, setDisplayScore] = useState(initialRating || 0); // Changé de 1 à 0

  // Mettre à jour le score quand initialRating change
  useEffect(() => {
    setScore(initialRating || 0);
    setDisplayScore(initialRating || 0);
  }, [initialRating]);

  const [comment, setComment] = useState('');
  const sliderContainerRef = useRef(null);
  const [hoveredStar, setHoveredStar] = useState(null);

  const handleSliderChange = (e) => {
    const newScore = parseFloat(e.target.value);
    setScore(newScore);
    setDisplayScore(Math.round(newScore));
    
    // Toujours déclencher onChange pour mettre à jour le tooltip
    if (onChange) {
      const meaning = getScoreEmoji(Math.round(newScore));
      onChange(Math.round(newScore), meaning);
    }
  };

  const handleSliderRelease = () => {
    if (onRelease && compact) {
      const meaning = getScoreEmoji(Math.round(score));
      onRelease(Math.round(score), meaning);
    }
  };

  const handleTouchMove = (e) => {
    if (compact) return;
    
    const touch = e.touches[0];
    const container = sliderContainerRef.current;
    const rect = container.getBoundingClientRect();
    const x = touch.clientX - rect.left;
    const width = rect.width;
    
    let newScore = (x / width) * 5;
    newScore = Math.max(0, Math.min(5, newScore));
    newScore = Math.round(newScore * 2) / 2; // Arrondir à 0.5 près
    
    setScore(newScore);
    setDisplayScore(Math.round(newScore));
    
    if (onChange) {
      const meaning = getScoreEmoji(Math.round(newScore));
      onChange(Math.round(newScore), meaning);
    }
  };

  const handleStarClick = (rating) => {
    setScore(rating);
    setDisplayScore(rating);
    if (onChange) {
      const meaning = getScoreEmoji(rating);
      onChange(rating, meaning);
    }
  };

  const renderStarsDisplay = () => {
    return (
      <div className="stars-display">
        <div className="stars-empty">{'★'.repeat(5)}</div>
        <div className="stars-filled" style={{ width: `${(score/5) * 100}%` }}>
          {'★'.repeat(5)}
        </div>
      </div>
    );
  };

  const renderClickableStars = () => {
    return (
      <div className="clickable-stars">
        {[1, 2, 3, 4, 5].map((rating) => (
          <span
            key={rating}
            className={`star ${rating <= (hoveredStar || score) ? 'filled' : 'empty'}`}
            onClick={() => handleStarClick(rating)}
            onMouseEnter={() => setHoveredStar(rating)}
            onMouseLeave={() => setHoveredStar(null)}
          >
            ★
          </span>
        ))}
      </div>
    );
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit({ score: displayScore, comment });
    }
  };

return (
    <div className={`notation-container ${compact ? 'compact' : ''}`}>
        {compact ? (
            <div 
                className="slider-container"
                ref={sliderContainerRef}
                onTouchMove={handleTouchMove}
                onTouchEnd={handleSliderRelease}
            >
                <div className='slider-stars'>
                <div className='slider-star-1'>☆</div>
                    <div className='slider-star-2'>☆</div>
                    <div className='slider-star-3'>☆</div>
                    <div className='slider-star-4'>☆</div>
                    <div className='slider-star-5'>☆</div>
                </div>
                <div className="slider-track">
                    <div className="slider-progress" style={{ width: `${(score/5) * 100}%` }}></div>
                </div>
                <input
                    type="range"
                    min="0"
                    max="5"
                    step="1"
                    value={score}
                    className="slider"
                    onChange={handleSliderChange}
                    onMouseUp={handleSliderRelease}
                    onTouchEnd={handleSliderRelease}
                />
            </div>
        ) : (
            <>
            <div className="modal-notation-space">
                {displayScore === 0 ? "⭐️" : getScoreEmojiOnly(displayScore)}
            </div>

                {renderClickableStars()}
                <div className="score-meaning">
                    {getScoreEmoji(displayScore)}
                </div>
                <textarea
                    className="comment-input"
                    placeholder="Comments..."
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                />
                <button 
                    className={`submit-button ${displayScore === 0 ? 'disabled' : ''}`}
                    onClick={handleSubmit}
                    disabled={displayScore === 0}
                >
                    Score !
                </button>
            </>
        )}
    </div>
);
};

export default Notation;
