import React from 'react';
import { FaStar, FaStarHalfAlt, FaRegStar } from 'react-icons/fa';
import './MyProfil.css';

const MyBadges = ({ badges }) => {
  const getLevelIcon = (level) => {
    switch(level) {
      case 1:
        return <FaRegStar className="level-star" />;
      case 2:
        return <FaStarHalfAlt className="level-star" />;
      case 3:
        return <FaStar className="level-star" />;
      default:
        return null;
    }
  };

  return (
    <div className="profile-bio">
      <h3>Badges</h3>
      <div className="badges-list">
        {(badges?.length > 0) ? (
          badges.map((badge, index) => (
            <div key={index} 
                 className={`badge-item ${badge.isNew ? 'new' : ''}`} 
                 title={badge.description}>
              <div dangerouslySetInnerHTML={{ __html: badge.icon }} />
              <span className="badge-name">{badge.name}</span>
              <span className="badge-level">
                {getLevelIcon(badge.level)}
              </span>
            </div>
          ))
        ) : (
          <p>No badge yet</p>
        )}
      </div>
    </div>
  );
};

export default MyBadges;