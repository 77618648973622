import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import GridListView from './GridListView';
import { getMyList, addToList as apiAddToList, removeFromList as apiRemoveFromList } from '../utils/api';
import './MyList.css';

function MyList() {
  const navigate = useNavigate();
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const eventSourceRef = useRef(null);

  const setupEventSource = () => {
    setLoading(true);
    setMyList([]); // Reset list when reconnecting
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
    
    // Fermer l'ancienne connexion si elle existe
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    console.log('Connecting to SSE...'); // Debug log
    eventSourceRef.current = new EventSource(`${baseUrl}/api/get-my-list`, { withCredentials: true });

    eventSourceRef.current.onopen = () => {
      console.log('SSE connection opened'); // Debug log
    };

    eventSourceRef.current.onmessage = (event) => {
      // console.log('SSE message received:', event.data);
      try {
        const item = JSON.parse(event.data);
        if (item.error) {
          console.error('SSE error:', item.error);
          return;
        }
        setMyList(currentList => {
          const exists = currentList.some(listItem => listItem.film_id === item.film_id);
          if (!exists) {
            // console.log('Adding new item to list:', item.title);
            return [...currentList, item]; // Ajout à la fin de la liste
          }
          return currentList;
        });
        setLoading(false);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    eventSourceRef.current.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSourceRef.current.close();
      setLoading(false);
    };
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        setupEventSource();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();

    return () => {
      if (eventSourceRef.current) {
        console.log('Closing SSE connection'); // Debug log
        eventSourceRef.current.close();
      }
    };
  }, [navigate]);

  const isInList = (filmId) => {
    return myList.some(item => item.film_id === filmId);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      
      // Reset list and reconnect SSE after rating
      setMyList([]);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      setupEventSource();
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
    try {
      await apiAddToList(filmId, mediaType, title, posterPath, genreIds);
      // Reset list and reconnect SSE after adding
      setMyList([]);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      setupEventSource();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  const removeFromList = async (filmId) => {
    try {
      await apiRemoveFromList(filmId);
      setMyList(prevList => prevList.filter(item => item.film_id !== filmId));
    } catch (error) {
      console.error('Error removing from list:', error);
    }
  };

  return (
    <div className="my-list">
      <h2>My List</h2>
      {loading ? (
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
          {/* <p>Loading your list...</p> */}
        </div>
      ) : (
        myList.length > 0 ? (
          <>
            <p>Found {myList.length} items</p>
            <GridListView 
              items={myList}
              isInList={isInList}
              addToList={addToList}
              handleScoring={handleScoring}
              onListUpdate={setupEventSource}
            />
          </>
        ) : (
          <p>Your list is empty</p>
        )
      )}
    </div>
  );
}

export default MyList;