// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-icon {
  font-size: 5rem;
  animation: spin 1s linear infinite;
}



@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.my-list {
    min-height: 100vh;
    padding: 20px;
    background-color: #111;
    color: white;
    padding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */
  }



`, "",{"version":3,"sources":["webpack://./src/components/MyList.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kCAAkC;AACpC;;;;AAIA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,YAAY;IACZ,oBAAoB,EAAE,gEAAgE;EACxF","sourcesContent":[".loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.loading-icon {\n  font-size: 5rem;\n  animation: spin 1s linear infinite;\n}\n\n\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.my-list {\n    min-height: 100vh;\n    padding: 20px;\n    background-color: #111;\n    color: white;\n    padding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */\n  }\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
