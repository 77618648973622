import React, { useState, useEffect, useRef } from 'react';
import TinderCard from 'react-tinder-card';
import { getScorePropositions, addToList, addToUnseenList, rateMovie } from '../utils/api';
import './TinderSwap.css';
import Modal from './Modal';
import Notation from './Notation';
import BlurayComponent from './BlurayComponent';  // Add this import

const TinderSwap = () => {
    const [cards, setCards] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const isFirstMount = useRef(true);
    const [cardCount, setCardCount] = useState({ current: 0, total: 0 }); // Nouveau state
    const [isRatingModalOpen, setIsRatingModalOpen] = useState(false);
    const [currentMovie, setCurrentMovie] = useState(null);

    useEffect(() => {
        const loadCards = async () => {
            try {
                const movies = await getScorePropositions();
                const formattedMovies = movies.map(film => ({
                    id: film.id,
                    title: film.title || film.name,
                    poster_path: `https://image.tmdb.org/t/p/w500${film.poster_path}`,
                    mediaType: film.media_type,
                    year: film.year,
                    genre_names: film.genre_names,
                    streaming_plateforms: film.streaming_plateforms,
                    director: film.director,
                    actors: film.actors,
                }));
                setCards(formattedMovies);
                setCardCount({ current: formattedMovies.length, total: formattedMovies.length }); // Initialisation du compteur
            } catch (error) {
                console.error("Erreur lors du chargement des cartes:", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (isFirstMount.current) {
            loadCards();
            isFirstMount.current = false;
        }
    }, []);

    const onSwipe = (direction) => {
        // Récupérer la dernière carte (celle du haut du stack)
        const swipedCard = cards[cards.length - 1];
        
        // Mettre à jour les cartes en retirant la dernière
        setCards(prevCards => prevCards.slice(0, -1));
        setCardCount(prev => ({ ...prev, current: prev.current - 1 }));

        let action;
        switch (direction) {
            case 'up':
            action = 'Put in My List';
            addToList(swipedCard.id, swipedCard.mediaType, swipedCard.title, swipedCard.poster_path);
            break;
            case 'down':
            action = 'Never ask me';
            addToUnseenList(swipedCard.id, swipedCard.mediaType, true);
            break;
            case 'left':
            action = 'Not seen yet';
            addToUnseenList(swipedCard.id, swipedCard.mediaType, false);
            break;
            case 'right':
            action = 'Already Seen!';
            setCurrentMovie(swipedCard);
            setIsRatingModalOpen(true);
            break;
            default:
            action = 'Unknown action';
        }
        // console.log(`Action for swiped card: ${action}`);

        // console.log('Swiped card:', {
        //     direction,
        //     id: swipedCard.id,
        //     mediaType: swipedCard.mediaType,
        //     title: swipedCard.title,
        //     cardsRemaining: cardCount.current - 1
        // });

        if (cardCount.current < 1) {
            console.log('Last card swiped');
            // Display reload button when only one card is remaining
            const reloadButton = document.createElement('button');
            reloadButton.innerText = 'Load More...';
            reloadButton.className = 'reload-button';
            reloadButton.onclick = () => window.location.reload();
            document.querySelector('.tinder-container').appendChild(reloadButton);
        }
    }

    const handleRatingSubmit = ({ score, comment }) => {
        // Utiliser les informations du film stockées dans currentMovie
        const { id, mediaType } = currentMovie;
        
        // Appeler l'API pour enregistrer la note
        rateMovie(id, mediaType, score, comment);
        
        // console.log(`Movie rated: ${score}, comment: ${comment}, id: ${id}, mediaType: ${mediaType}`);
        rateMovie(id, mediaType, score, comment);
        setIsRatingModalOpen(false);
        setCurrentMovie(null); // Réinitialiser le film courant
        setCardCount(prev => ({ ...prev, current: prev.current - 1 }));
    };

    return (
        <div className="tinder-wrapper">
            <div className="tinder-container">
                <div className="swipe-hints">
                    <div className="hint hint-up">
                        <i className="fas fa-chevron-up"></i>
                        Put in My List
                        <i className="fa-regular fa-flag"></i>
                    </div>
                    <div className="hint hint-left">
                        <i className="fas fa-chevron-up"></i>
                        Not seen yet 
                        <i className="fa-solid fa-question"></i>
                    </div>
                    <div className="hint hint-right">
                        <i className="fas fa-chevron-up"></i>
                        Already Seen!
                        <i className="fa-solid fa-check"></i>
                    </div>
                    <div className="hint hint-down">
                        <i className="fas fa-chevron-down"></i>
                        Never ask me
                        <i className="fa-solid fa-xmark"></i>
                    </div>
                </div>

                <div className="cards-count">
                    {cardCount.current} / {cardCount.total}
                </div>

                <div className="card-container">
                    {cards.map((card) => (
                        <TinderCard
                            key={card.id}
                            onSwipe={onSwipe}
                            className="swipe"
                            swipeRequirementType="position"
                            swipeThreshold={120}
                        >
                            <BlurayComponent 
                                item={{
                                    film_id: card.id,
                                    title: card.title,
                                    media_type: card.mediaType,
                                    poster_path: card.poster_path,
                                    genre_names: card.genre_names,
                                    streaming_plateforms: card.streaming_plateforms,
                                    director: card.director,
                                    actors: card.actors,
                                    year: card.year
                                }}
                                showButtons={false}
                                showScorings={false}
                            />
                        </TinderCard>
                    ))}
                </div>
            </div>
            {isLoading && (
                <div className="loading-tinder">
                    <div className="loading-spinner-tinder"></div>
                    <div className="loading-text"></div>
                </div>
            )}
            <Modal 
                isOpen={isRatingModalOpen} 
                showCloseButton={false}
                onClose={() => setIsRatingModalOpen(false)}
            >
                <div className="rating-modal-content">
                    <h2>Score {currentMovie?.title}</h2>
                    <Notation 
                        onSubmit={handleRatingSubmit}
                        showTooltip={true}
                        compact={false}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default TinderSwap;