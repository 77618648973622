// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.film-details {
  padding: 20px;
  max-height: 80vh;
  overflow-y: auto;
  color: white;
}

.loading, .error {
  text-align: center;
  padding: 20px;
}

.summary-details {
  margin-bottom: 20px;
}

.cast-section {
  margin: 20px 0;
}

.cast-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.cast-item {
  text-align: center;
}

.cast-item img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.cast-info {
  margin-top: 5px;
}

.actor-name {
  font-weight: bold;
  display: block;
  font-size: 0.9em;
}

.character-name {
  font-size: 0.8em;
  color: #61dafb;
  display: block;
}

.platforms-list {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 20px;
}

.platform-item {
  display: flex;
  align-items: center;
  gap: 5px;
}

.platform-item img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/FilmDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,QAAQ;AACV;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".film-details {\n  padding: 20px;\n  max-height: 80vh;\n  overflow-y: auto;\n  color: white;\n}\n\n.loading, .error {\n  text-align: center;\n  padding: 20px;\n}\n\n.summary-details {\n  margin-bottom: 20px;\n}\n\n.cast-section {\n  margin: 20px 0;\n}\n\n.cast-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));\n  gap: 15px;\n  margin-top: 15px;\n}\n\n.cast-item {\n  text-align: center;\n}\n\n.cast-item img {\n  width: 100%;\n  height: auto;\n  border-radius: 8px;\n}\n\n.cast-info {\n  margin-top: 5px;\n}\n\n.actor-name {\n  font-weight: bold;\n  display: block;\n  font-size: 0.9em;\n}\n\n.character-name {\n  font-size: 0.8em;\n  color: #61dafb;\n  display: block;\n}\n\n.platforms-list {\n  display: flex;\n  gap: 10px;\n  flex-wrap: wrap;\n  margin-top: 20px;\n}\n\n.platform-item {\n  display: flex;\n  align-items: center;\n  gap: 5px;\n}\n\n.platform-item img {\n  width: 30px;\n  height: 30px;\n  object-fit: contain;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
