import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import GridListView from './GridListView';
import { getScoreEmoji, isInList } from '../utils/helpers';
import './Search.css';
import { getMyList } from '../utils/api';


function Search({ addToList }) {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState([]);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showScoringModal, setShowScoringModal] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [error, setError] = useState('');
  const [userList, setUserList] = useState([]);

  const fetchListStatus = async () => {
    try {
      const response = await getMyList();
      setUserList(response.data);
    } catch (error) {
      console.error('Error fetching user list:', error);
    }
  };

  const isInUserList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };

  useEffect(() => {
    fetchListStatus();
  }, []);

  const handleScroll = (direction) => {
    if (direction === 'up' && currentIndex < results.length - 1) {
      setCurrentIndex(prev => prev + 1);
    } else if (direction === 'down' && currentIndex > 0) {
      setCurrentIndex(prev => prev - 1);
    }
  };

  const handleMovieClick = async (index) => {
    setCurrentIndex(index);
    setFullscreenMode(true);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      performSearch(query); // Refresh results after rating
      fetchListStatus(); // Refresh list status after rating
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const getImageUrl = (posterPath) => {
    if (posterPath) {
      return `https://image.tmdb.org/t/p/w500${posterPath}`;
    }
    return 'https://via.placeholder.com/500x750/1a1a1a/61dafb?text=No+Poster';
  };

  const performSearch = useCallback((searchQuery) => {
    if (searchQuery.length > 0) {
      axios.get(`/api/search?query=${searchQuery}`)
        .then(response => {
          setResults(response.data);
          setError('');
        })
        .catch(error => {
          console.error('There was an error searching for movies!', error);
          setError('An error occurred while searching. Please try again later.');
        });
    } else {
      setResults([]);
      setError('');
    }
  }, []);

  useEffect(() => {
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    const timeoutId = setTimeout(() => {
      performSearch(query);
    }, 500);

    setTypingTimeout(timeoutId);

    return () => {
      if (typingTimeout) {
        clearTimeout(typingTimeout);
      }
    };
  }, [query, performSearch]);

  return (
    <div className="search-page">
      <h2>Search Movies and TV Shows</h2>
      <input
        type="text"
        placeholder="Search..."
        value={query}
        onChange={(e) => setQuery(e.target.value)}
      />
      {error && <p className="error-message">{error}</p>}

      <GridListView 
        items={results}
        currentIndex={currentIndex}
        isInList={isInUserList}
        addToList={addToList}
        handleScoring={handleScoring}
        onListUpdate={() => {
          performSearch(query);
          fetchListStatus();
        }}
      />  
    </div>
  );
}

export default Search;