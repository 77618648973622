import React from 'react';

// Fonction pour rendre les étoiles basées sur le score
export const renderStars = (score) => {
  const fullStars = Math.floor(score);
  const emptyStars = 5 - fullStars;
  return (
    <div className="stars">
      {'★'.repeat(fullStars)}
      {'☆'.repeat(emptyStars)}
    </div>
  );
};

// Fonction pour obtenir l'emoji et le texte correspondant au score
export const getScoreEmoji = (score) => {
  switch(score) {
    case 5: return "❤️ Coup de coeur!";
    case 4: return "🤩 Excellent!";
    case 3: return "👍 Plutôt bien";
    case 2: return "😐 Ça se regarde";
    case 1: return "💩 Mauvais";
    default: return "";
  }
};

// Fonction pour obtenir l'emoji et le texte correspondant au score
export const getScoreEmojiOnly = (score) => {
  switch(score) {
    case 5: return "❤️";
    case 4: return "🤩";
    case 3: return "👍";
    case 2: return "😐";
    case 1: return "💩";
    default: return "";
  }
};
// Fonction pour obtenir l'URL de l'image du poster
export const getImageUrl = (posterPath) => {
  if (posterPath) {
    return `https://image.tmdb.org/t/p/w500${posterPath}`;
  }
  return 'https://via.placeholder.com/500x750/1a1a1a/61dafb?text=No+Poster';
};

// Fonction pour formater la date
export const formatDate = (dateString) => {
  if (!dateString) return 'Date inconnue';
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(dateString).toLocaleDateString('fr-FR', options);
};

// Fonction pour tronquer le texte
export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  return text.substr(0, maxLength) + '...';
};

// Fonction pour vérifier si un film est dans la liste
export const isInList = (list, filmId) => {
  if (!Array.isArray(list)) {
    console.warn('isInList: list is not an array', list);
    return false;
  }
  return list.some(item => item.film_id === filmId);
};

// Fonction pour obtenir le bon ID d'un item (film ou série)
export const getItemId = (item) => {
  return item.film_id || item.id;
};

// Fonction pour obtenir le titre d'un item (film ou série)
export const getItemTitle = (item) => {
  return item.title || item.name;
};

// Fonction pour calculer la moyenne des scores
export const calculateAverageScore = (ratings) => {
  if (ratings.length === 0) return 0;
  const sum = ratings.reduce((acc, rating) => acc + rating.score, 0);
  return (sum / ratings.length).toFixed(1);
};

// Fonction pour générer une couleur aléatoire
export const getRandomColor = () => {
  return '#' + Math.floor(Math.random()*16777215).toString(16);
};

// Fonction pour valider l'email
export const validateEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

// Fonction pour formater la durée en heures et minutes
export const formatRuntime = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours}h ${remainingMinutes}min`;
};

// Fonction pour obtenir l'année à partir d'une date
export const getYearFromDate = (dateString) => {
  return new Date(dateString).getFullYear();
};

export default {
  renderStars,
  getScoreEmoji,
  getImageUrl,
  formatDate,
  truncateText,
  isInList,
  getItemId,
  getItemTitle,
  calculateAverageScore,
  getRandomColor,
  validateEmail,
  formatRuntime,
  getYearFromDate
};