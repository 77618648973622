import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSignOutAlt, FaUsers, FaStar, FaHandshake } from 'react-icons/fa';
import GridListView from './GridListView';
import { getAllUserScoring, rateMovie, getMyList, getCompatibility, addRemoveFollower } from '../utils/api';
import './MyProfil.css';

function MyProfile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    pseudo: '',
    bio: '',
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [userScoring, setUserScoring] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userCompatibility, setUserCompatibility] = useState({});

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        
        const fetchProfile = async () => {
          try {
            const response = await axios.get('/api/get-profile');
            setProfileData(response.data);
            setEditForm({
              pseudo: response.data.pseudo,
              bio: response.data.bio || '',
            });
            setPreviewUrl(response.data.avatar_url || '');
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        fetchProfile();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();

    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/get-all-users');
        const usersData = response.data;
        
        // Fetch compatibility for each user
        const compatibilityPromises = usersData
          .filter(user => user.user_id !== profileData?.user_id)
          .map(async (user) => {
            const compatibility = await getCompatibility(user.user_id);
            return [user.user_id, compatibility];
          });
        
        const compatibilityResults = await Promise.all(compatibilityPromises);
        const compatibilityMap = Object.fromEntries(compatibilityResults);
        
        setUsers(usersData);
        setUserCompatibility(compatibilityMap);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();

    const fetchUserScoring = async () => {
      try {
        const scoringData = await getAllUserScoring();
        setUserScoring(scoringData);
      } catch (error) {
        console.error('Error fetching user scoring:', error);
      }
    };

    fetchUserScoring();

    const fetchUserList = async () => {
      try {
        const response = await getMyList();
        setUserList(response.data || []);
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    };

    fetchUserList();
  }, [navigate]);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB en bytes
        setError('File size must be less than 5MB');
        return;
      }
      setAvatarFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('pseudo', editForm.pseudo);
      formData.append('bio', editForm.bio);
      if (avatarFile) {
        formData.append('avatar', avatarFile);
      }

      const response = await axios.post('/api/update-profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProfileData({ 
        ...profileData, 
        ...editForm,
        avatar_url: response.data.avatar_url || profileData.avatar_url 
      });
      setIsEditing(false);
      setError('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.response?.data?.error || 'Error updating profile');
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      window.location = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const handleFollowToggle = async (userId) => {
    try {
      const action = users.find(user => user.user_id === userId)?.is_follower ? 'remove' : 'add';
      const response = await addRemoveFollower(userId, action);
      
      if (response) {
        setUsers(users.map(user => 
          user.user_id === userId 
            ? { ...user, is_follower: !user.is_follower }
            : user
        ));

        // Mettre à jour les compatibilités après le changement de following
        const compatibility = await getCompatibility(userId);
        setUserCompatibility(prev => ({
          ...prev,
          [userId]: compatibility
        }));
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
    }
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await rateMovie(filmId, mediaType, score, comments);
      // Rafraîchir les données après notation
      const updatedScoring = await getAllUserScoring();
      setUserScoring(updatedScoring);
    } catch (error) {
      console.error('Error scoring movie:', error);
      setError('Failed to rate movie');
    }
  };

  const isInList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };

  const handleUserClick = (userId) => {
    navigate(`/user/${userId}`);
  };

  if (!profileData) return <p>Loading profile...</p>;

  return (
    <div className="profile-page">
      <h2>Mon Profil</h2>
      <button className="logout-button" onClick={handleLogout}>
        <FaSignOutAlt /> Logout
      </button>
      {!isEditing ? (
        <>
          <div className="profile-header">
            <img 
              src={profileData.avatar_url || 'https://via.placeholder.com/150'} 
              alt="Profile" 
              className="profile-avatar"
            />
            <h2>{profileData.pseudo}</h2>
            <button className="edit-button" onClick={() => setIsEditing(true)}>
              Edit Profile
            </button>
          </div>
          
          <div className="profile-stats">
            <div className="stat-item">
              <span className="stat-value">{profileData.rated_movie_count}</span>
              <span className="stat-label">Movies Rated</span>
            </div>
            <div className="stat-item">
              <span className="stat-value">{profileData.rated_tv_count}</span>
              <span className="stat-label">TV Shows Rated</span>
            </div>
          </div>

          <div className="profile-bio">
            <h3>About Me</h3>
            <p>{profileData.bio || 'No bio yet'}</p>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="profile-edit-form">
          <div className="form-group avatar-upload">
            <label className="avatar-label">
              <img 
                src={previewUrl || 'https://via.placeholder.com/150'} 
                alt="Profile" 
                className="profile-avatar"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                className="hidden"
              />
              <span className="upload-overlay">Change Avatar</span>
            </label>
            {error && <div className="error-message">{error}</div>}
          </div>
          
          <div className="form-group">
            <label>Pseudo</label>
            <input
              type="text"
              value={editForm.pseudo}
              onChange={(e) => setEditForm({ ...editForm, pseudo: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label>Bio</label>
            <textarea
              value={editForm.bio}
              onChange={(e) => setEditForm({ ...editForm, bio: e.target.value })}
              rows="4"
            />
          </div>

          <div className="form-buttons">
            <button type="submit">Save</button>
            <button type="button" onClick={() => {
              setIsEditing(false);
              setError('');
              setPreviewUrl(profileData.avatar_url || '');
            }}>Cancel</button>
          </div>
        </form>
      )}

      <div className="user-ratings-section">
        <h3><FaUsers /> Followings</h3>
        <div className="users-list">
          {users.filter(user => user.user_id !== profileData?.user_id).map(user => (
            <div key={user.user_id} className="user-item">
              <div 
                className="user-info"
                onClick={() => handleUserClick(user.user_id)}
                style={{ cursor: 'pointer' }}
              >
                <img 
                  src={user.avatar_url || 'https://via.placeholder.com/150'} 
                  alt={user.pseudo}
                  className="user-avatar"
                />
                <div className="user-details">
                  <span className="user-pseudo">{user.pseudo}</span>
                  {userCompatibility[user.user_id] !== undefined && (
                    <div className="compatibility-badge">
                      <FaHandshake className="compatibility-icon" />
                      <span
                        style={{
                          color: userCompatibility[user.user_id] > 70 ? '#4CAF50' : 
                                userCompatibility[user.user_id] > 40 ? '#FFA726' : '#F44336'
                        }}
                      >
                        {userCompatibility[user.user_id]}%
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <label className="switch">
                <input
                  type="checkbox"
                  checked={user.is_follower}
                  onChange={() => handleFollowToggle(user.user_id)}
                />
                <span className="slider round"></span>
              </label>
            </div>
          ))}
        </div>
      </div>

      <div className="user-ratings-section">
        <h3><FaStar /> Mes Films & Séries Notés</h3>
        {userScoring.length > 0 ? (
          <GridListView
            items={userScoring}
            isInList={isInList}
            handleScoring={handleScoring}
            onListUpdate={async () => {
              const [updatedScoring, updatedList] = await Promise.all([
                getAllUserScoring(),
                getMyList()
              ]);
              setUserScoring(updatedScoring);
              setUserList(updatedList.data || []);
            }}
          />
        ) : (
          <p>Aucun film ou série noté pour le moment</p>
        )}
      </div>
    </div>
  );
}

export default MyProfile;