// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/BottomNavBar.css */
.bottom-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-around;
    background-color: #333;
    padding: 10px 0;
  }
  
  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    text-decoration: none;
  }
  
  .nav-item svg {
    font-size: 24px;
    margin-bottom: 5px;
  }
  
  .nav-item span {
    font-size: 12px;
  }`, "",{"version":3,"sources":["webpack://./src/components/BottomNavBar.css"],"names":[],"mappings":"AAAA,oCAAoC;AACpC;IACI,eAAe;IACf,SAAS;IACT,OAAO;IACP,QAAQ;IACR,aAAa;IACb,6BAA6B;IAC7B,sBAAsB;IACtB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,YAAY;IACZ,qBAAqB;EACvB;;EAEA;IACE,eAAe;IACf,kBAAkB;EACpB;;EAEA;IACE,eAAe;EACjB","sourcesContent":["/* src/components/BottomNavBar.css */\n.bottom-nav {\n    position: fixed;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    display: flex;\n    justify-content: space-around;\n    background-color: #333;\n    padding: 10px 0;\n  }\n  \n  .nav-item {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    color: white;\n    text-decoration: none;\n  }\n  \n  .nav-item svg {\n    font-size: 24px;\n    margin-bottom: 5px;\n  }\n  \n  .nav-item span {\n    font-size: 12px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
