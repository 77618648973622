import React, { useState, useEffect } from 'react';
import { FaStar, FaPlay, FaInfo, FaSearch } from 'react-icons/fa';
import { renderStars, getScoreEmoji, getScoreEmojiOnly } from '../utils/helpers';
import { addToList, removeFromList, getStreamingPlatforms, getMovieDetails, getTrailer, getUserRating, getCasting, isInMyList, likeComment } from '../utils/api';
import './GridListView.css';
import Modal from './Modal';

// Modify ItemButtons to be a forwardRef component
const ItemButtons = React.forwardRef(({ item, onListUpdate, handleScoring }, ref) => {
  const [modalType, setModalType] = useState(null);
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [movieDetails, setMovieDetails] = useState({});
  const [comment, setComment] = useState('');
  const [trailerData, setTrailerData] = useState(null);
  const [userRating, setUserRating] = useState(null);
  const [castData, setCastData] = useState([]);
  const [inList, setInList] = useState(item.is_in_list || false);  // Initialiser avec is_in_list
  const [showScoreAnimation, setShowScoreAnimation] = useState(false);
  const [scoreIcons, setScoreIcons] = useState([]);
  const [ratings, setRatings] = useState(item.ratings?.map(rating => ({
    ...rating,
    is_liked: rating.ilikecomment // Initialiser is_liked avec ilikecomment
  })) || []);

  // Expose setModalType to parent
  React.useImperativeHandle(ref, () => ({
    setModalType
  }));

  const getItemId = (item) => {
    return item.film_id || item.id;
  };

  const handleListAction = async () => {
    const id = getItemId(item);
    try {
      if (inList) {
        await removeFromList(id);
        setInList(false);
      } else {
        await addToList(
          id,
          item.media_type,
          item.title,
          item.poster_path,
          item.genre_ids
        );
        setInList(true);
      }
      onListUpdate && onListUpdate();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  useEffect(() => {
    const loadData = async () => {
      if (modalType === 'trailer' || modalType === 'summary') {
        setLoading(true);
        try {
          const [platformsData, trailerData, movieDetailsData, castingData] = await Promise.all([
            getStreamingPlatforms(item.media_type, getItemId(item)),
            getTrailer(item.media_type, getItemId(item)),
            modalType === 'summary' ? getMovieDetails(item.media_type, getItemId(item)) : Promise.resolve(null),
            modalType === 'summary' ? getCasting(item.media_type, getItemId(item)) : Promise.resolve([])
          ]);

          setPlatforms(platformsData || []);
          setTrailerData(trailerData);
          setCastData(castingData || []);
          if (modalType === 'summary' && movieDetailsData) {
            setMovieDetails(movieDetailsData.data);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    loadData();
  }, [modalType, item]);

  useEffect(() => {
    if (modalType) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [modalType]);

  useEffect(() => {
    const fetchUserRating = async () => {
      try {
        const rating = await getUserRating(getItemId(item), item.media_type);
        setUserRating(rating); // rating sera null si pas de note
      } catch (error) {
        console.error('Error fetching user rating:', error);
        setUserRating(null);
      }
    };
    fetchUserRating();
  }, [item]);

  const handleScoreSubmit = async (score) => {
    const filmId = String(item.film_id || item.id);
    await handleScoring(filmId, item.media_type, score, comment);  // Ajout du commentaire
    setComment('');  // Reset le commentaire
    triggerScoreAnimation(score);
  };

  const triggerScoreAnimation = (score) => {
    const icons = Array(30).fill(getScoreEmoji(score).split(' ')[0]);
    setScoreIcons(icons);
    setShowScoreAnimation(true);
    setTimeout(() => {
      setShowScoreAnimation(false);
      setModalType(null);
    }, 2000);
  };

  const renderScoreIcons = () => {
    return scoreIcons.map((icon, index) => {
      const left = Math.random() * 100 + 'vw';
      const delay = Math.random() * 1 + 's';
      return (
        <span
          key={index}
          className="score-icon"
          style={{ left, animationDelay: delay }}
        >
          {icon}
        </span>
      );
    });
  };

  const handleLikeComment = async (ratingId) => {
    try {
      const response = await likeComment(ratingId);
      // Mettre à jour le state des ratings avec les nouvelles données
      setRatings(prevRatings => prevRatings.map(rating => {
        if (rating.id === ratingId) {
          return {
            ...rating,
            is_liked: !rating.is_liked,
            likes: rating.is_liked ? rating.likes - 1 : rating.likes + 1
          };
        }
        return rating;
      }));
    } catch (error) {
      console.error('Error liking comment:', error);
    }
  };

  const renderModalContent = () => {
    switch (modalType) {
      case 'trailer':
        return (
          <div className="platforms-container">
            {trailerData && typeof trailerData === 'string' && (
              <div className="trailer-container">
                <iframe
                  width="100%"
                  height="250"
                  src={trailerData.replace('youtube.com/watch?v=', 'youtube.com/embed/')}
                  title="Trailer"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        );

      case 'scoring':
        return (
          <div className="scoring-options">
            <textarea
              className="comment-input"
              placeholder="Commentaires"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={2}
            />
            {[5, 4, 3, 2, 1].map((score) => (
              <button
                key={score}
                className="score-button"
                onClick={() => {
                  handleScoreSubmit(score);
                  setModalType(null);
                }}
              >
                <span className="score-emoji">{getScoreEmoji(score).split(' ')[0]}</span>
                <span className="score-text">{getScoreEmoji(score).split(' ').slice(1).join(' ')}</span>
              </button>
            ))}
          </div>
        );

      case 'summary':
        return (
          <>
            <div className="summary-details">
              <p>{movieDetails?.overview || 'Non disponible'}</p>
              <p>{movieDetails?.release_date || movieDetails?.first_air_date || 'Non disponible'}</p>
              <p>{movieDetails?.runtime || movieDetails?.episode_run_time?.[0] || 'Non disponible'} minutes</p>
            </div>
            <div className="cast-section">
              <h3>Casting</h3>
              <div className="cast-grid">
                {castData.map((actor) => (
                  <div key={actor.id} className="cast-item">
                    <img 
                      src={actor.profile_path ? 
                        `https://image.tmdb.org/t/p/w185${actor.profile_path}` : 
                        'https://via.placeholder.com/185x278?text=No+Image'
                      } 
                      alt={actor.name}
                    />
                    <div className="cast-info">
                      <span className="actor-name">{actor.name}</span>
                      <span className="character-name">{actor.character}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="platforms-list">
              {loading ? (
                <div className="loading">Chargement...</div>
              ) : platforms.length > 0 ? (
                platforms.map((platform, index) => (
                  <div key={index} className="platform-item">
                    <img src={platform.logo} alt={platform.name} />
                    <span>{platform.name}</span>
                  </div>
                ))
              ) : (
                <p>Non disponible en streaming pour le moment</p>
              )}
            </div>
          </>
        );

      case 'comments':
        return (
          <div className="comments-modal">
            <h3>Ratings & Comments</h3>
            <div className="ratings-list">
              {ratings.map((rating, idx) => (
                <div key={idx} className="rating-item">
                  <div className="rating-header">
                    <img src={rating.avatar_url} alt={`${rating.pseudo}'s avatar`} className="avatar-small" />
                    <a href={`/user/${rating.user_id}`} className="rating-pseudo">
                      {rating.pseudo}
                    </a>
                  </div>
                  <div className="rating-content">
                    {renderStars(rating.score)}
                    {rating.comments && (
                      <>
                        <p className="comments-rating-comment">{rating.comments}</p>
                        <button 
                          className={`like-button ${rating.is_liked ? 'liked' : ''}`}
                          onClick={() => handleLikeComment(rating.id)}
                        >
                          <i className="fa fa-heart"></i>
                          {rating.likes > 0 && ` ${rating.likes}`}
                        </button>
                      </>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="grid-list-buttons">
        <button 
          className={`action-button ${inList ? 'active' : ''}`}
          onClick={handleListAction}
        >
          <span className="button-icon">{inList ? '🚩' : '⚐'}</span>
          <span className="button-label">My List</span>
        </button>
        
        <button 
          className={`action-button ${userRating !== null ? 'rated' : ''}`}
          onClick={() => setModalType('scoring')}
        >
          <span className="button-icon">
            <FaStar style={{ color: userRating !== null ? '#ff4444' : 'white' }} />
            {userRating !== null && (
              <span className="rating-value" style={{ 
                color: 'white',
                fontWeight: 'bold',
                marginLeft: '4px'
              }}>
                {userRating}
              </span>
            )}
          </span>
          <span className="button-label">Score</span>
        </button>

        <button 
          className="action-button"
          onClick={() => setModalType('trailer')}
        >
          <span className="button-icon"><FaPlay /></span>
          <span className="button-label">Trailers</span>
        </button>

        <button 
          className="action-button"
          onClick={() => setModalType('summary')}
        >
          <span className="button-icon"><FaInfo /></span>
          <span className="button-label">Infos</span>
        </button>
      </div>
      {showScoreAnimation && (
        <div className="score-animation">
          {renderScoreIcons()}
        </div>
      )}

      <Modal
        isOpen={!!modalType}
        onClose={() => setModalType(null)}
        title={modalType === 'summary' ? (movieDetails?.original_title || movieDetails?.original_name || '') : ''}
      >
        {loading ? (
          <div className="loading">Chargement...</div>
        ) : (
          renderModalContent()
        )}
      </Modal>
    </>
  );
});

function GridListView({ items = [], currentIndex, onClose, onScroll, handleScoring, onListUpdate, isReadOnly = false }) {
  // Ensure items is always an array
  const safeItems = Array.isArray(items) ? items : [];
  
  const navigateToPersonSearch = (personId) => {
    window.location.href = `/search?query_person=${personId}`;
  };

  return (
    <div className="grid-container activity-grid">
      {safeItems.map((item, index) => (
        <div 
          key={index}
          className={item.media_type === 'badge' ? 'grid-item-badge' : item.media_type === 'activity-text' ? 'grid-item-text' : 'grid-item'}
          style={{
            backgroundImage: item.media_type === 'badge'
              ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
                 url(${item.poster_path})`
              : item.media_type === 'person'
                ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
                    url(https://image.tmdb.org/t/p/original${item.poster_path})`
                : ``
          }}
        >

          {item.media_type === 'person' ? (

            // Affichage pour les personnes
            <>
              <p className='media-type'>{item.media_type === 'badge' ? 'Social' : item.media_type === 'movie' ? 'Movie' : item.media_type === 'tv' ? 'TV Show' : item.media_type === 'person' ? 'People' : ''}</p>
              <div className="grid-content-header">
                <h3 className="item-title">{item.name}</h3>
                {/* <p className='media-type'>{item.media_type === 'person' ? 'People' : ''}</p> */}
                <div className='streaming-platforms'>
                  {item.genre_names && item.genre_names.map((genre, index) => (
                    <span key={index} className="genre-tag">{genre}</span>
                  ))}
                </div>
                <div className='genre'>
                  {item.known_for && item.known_for.map((platform, index) => (
                    <span key={index} className="genre-tag">
                      {platform.title}
                    </span>
                  ))}
                </div>
                <button
                  className="person-search-button"
                  onClick={() => navigateToPersonSearch(item.id)}
                >
                  <FaSearch /> Films & TV Shows with this person
                </button>
              </div>
            </>
          ) : item.media_type === 'badge' ? (
            // Affichage pour les badges
            <>
              <p className='media-type'>{item.media_type === 'badge' ? 'Social' : item.media_type === 'movie' ? 'Movie' : item.media_type === 'tv' ? 'TV Show' : item.media_type === 'person' ? 'People' : ''}</p>
              <div className="grid-content-header">
                <h3 className="item-title">{item.title}</h3>
                {/* <p className='media-type'>{item.media_type === 'badge' ? 'Badge' : ''}</p> */}
                <p className='badge-description'>{item.description}</p>


                <div className="leaderboard">
                  {item.level_3 && item.level_3.length > 0 && (
                    <>
                      <div className='level gold'><h2>🏆 Expert</h2>
                        <div className='users-badge'>
                          {item.level_3.map((pseudo, index) => (
                            <span key={index} className="user-badge">
                              {pseudo}
                            </span>
                          ))}
                        </div>
                      </div>
                    </>
                  )}

                  {item.level_2 && item.level_2.length > 0 && (
                    <>
                      <div className='level silver'><h2>🥈 Intermediate</h2>
                        <div className='users-badge'>
                          {item.level_2 && item.level_2.map((pseudo, index) => (
                            <span key={index} className="user-badge">
                              {pseudo}
                            </span>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  {item.level_1 && item.level_1.length > 0 && (
                    <>
                      <div className='level bronze'><h2>🥉 Rookie</h2>
                        <div className='users-badge'>
                          {item.level_1 && item.level_1.map((pseudo, index) => (
                            <span key={index} className="user-badge">{pseudo}</span>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </div>

              </div>
            </>
          ) : (
            // Affichage pour les films et séries
            <>

              <div className="bluray-case">
                <div className="cover">
                  <div className="top-bar">
                    <div className="bluray-titre">{item.title}</div>
                    <div className="bluray-logo">{item.media_type === 'tv' ? 'TV SHOW' : 'MOVIE'}™</div>
                  </div>
                  <div className="bluray-overlay-haut">
                    <div className='genre'>
                      {item.genre_names && item.genre_names.map((genre, index) => (
                        <span key={index} className="genre-tag">{genre}</span>
                      ))}
                    </div>
                    <div className='streaming-platforms'>
                      {item.streaming_plateforms && item.streaming_plateforms.map((platform, index) => (
                        <span key={index} className="genre-tag">
                          {platform.name}
                        </span>
                      ))}
                    </div>
                  </div>
                  <div className="cover-image" style={{
                    backgroundImage: item.media_type === 'badge'
                      ? `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
                    url(${item.poster_path})`
                      : `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
                    url(https://image.tmdb.org/t/p/original${item.poster_path})`
                  }}></div>
                </div>
                <div className="spine"></div>
              </div>

              <div className="grid-content-header">
              </div>
              {!isReadOnly && (
                <ItemButtons
                  item={item}
                  onListUpdate={onListUpdate}
                  handleScoring={handleScoring}
                  ref={(ref) => {
                    // Store the ref for this item's buttons
                    item.buttonsRef = ref;
                  } } />
              )}
              <div
                className="item-overlay"
                onClick={() => {
                  // Set modalType to 'comments' directly in the ItemButtons component
                  if (item.buttonsRef) {
                    item.buttonsRef.setModalType('comments');
                  }
                } }
              >
                <div className="ratings-preview">
                  {item.ratings && item.ratings.slice(0, 10).map((rating, idx) => (
                    <div key={idx} className="rating-compact">
                      <img src={rating.avatar_url} alt={`${rating.pseudo}'s avatar`} className="avatar-small" />
                      <div className="rating-details">
                        {/* <a href={`/user/${rating.user_id}`} className='rating-pseudo'> */}
                        <span className="rating-pseudo">{rating.pseudo}</span>
                        <div className="rating-score-comment">
                          {renderStars(rating.score)}
                          {rating.comments && (
                            <span className="rating-comment">{rating.comments}</span>
                          )}
                        </div>
                        {/* </a> */}
                      </div>
                    </div>
                  ))}
                  {item.ratings && item.ratings.length > 10 && (
                    <span className="more-ratings">+{item.ratings.length - 10} more</span>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      ))}
      <br />
    </div>
  );
}

export default GridListView;