import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import GridListView from './GridListView';
import { getRecommendations, addToList as apiAddToList } from '../utils/api';
import './MyReco.css';

function MyReco() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const otherUserId = searchParams.get('users');
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState('');
  const [currentUserId, setCurrentUserId] = useState(null);
  const eventSourceRef = useRef(null);
  const [user1Data, setUser1Data] = useState(null);
  const [user2Data, setUser2Data] = useState(null);

  const setupEventSource = () => {
    setLoading(true);
    setRecommendations([]); // Reset recommendations when reconnecting
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
    
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    // Choose endpoint based on whether otherUserId is present
    const endpoint = otherUserId 
      ? `/api/get-common-recommendations?user_id_1=${otherUserId}&user_id_2=${currentUserId}`
      : '/api/get-recommendations';

    eventSourceRef.current = new EventSource(`${baseUrl}${endpoint}`, { withCredentials: true });

    eventSourceRef.current.onopen = () => {
      console.log('Recommendations SSE connection opened');
    };

    eventSourceRef.current.onmessage = (event) => {
      //console.log('Recommendation received:', event.data);
      try {
        const data = JSON.parse(event.data);
        
        // Si c'est un message d'étape, mettre à jour currentStep
        if (data.step) {
          setCurrentStep(data.step);
          return;
        }
        
        // Si c'est un film (vérifié par la présence du type 'film')
        if (data.type === 'film') {
          setRecommendations(currentRecos => {
            const exists = currentRecos.some(reco => reco.film_id === data.film_id);
            if (!exists) {
              return [...currentRecos, data];
            }
            return currentRecos;
          });
        }
        
        setLoading(false);
      } catch (error) {
        console.error('Error parsing recommendation data:', error);
      }
    };

    eventSourceRef.current.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSourceRef.current.close();
      setLoading(false);
    };
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        setCurrentUserId(response.data.user.id);
        
        // Si on a un otherUserId, récupérer les infos des deux utilisateurs
        if (otherUserId) {
          const [user1Response, user2Response] = await Promise.all([
            axios.get(`/api/get-profile?user_id=${otherUserId}`),
            axios.get('/api/get-profile')
          ]);
          setUser1Data(user1Response.data);
          setUser2Data(user2Response.data);
        }
        
        setupEventSource();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
    };
  }, [navigate, otherUserId]);
  
  const fetchInitialData = async () => {
    try {
      const recoResponse = await getRecommendations();
      setRecommendations(recoResponse);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching initial data:', error);
      setLoading(false);
    }
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      await fetchInitialData();
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
    try {
      await apiAddToList(filmId, mediaType, title, posterPath, genreIds);
      await fetchInitialData(); // Rafraîchir les recommandations pour mettre à jour l'état is_in_list
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  const renderTitle = () => {
    if (!otherUserId) return <h2>Recommandations</h2>;
    
    if (user1Data && user2Data) {
      return (
        <div className="recommendations-title">
          <div className="users-comparison">
            <div className="user-avatar-wrapper">
              <img 
                src={user1Data.avatar_url || 'https://via.placeholder.com/50'} 
                alt={user1Data.pseudo}
                className="user-avatar"
              />
              <span>{user1Data.pseudo}</span>
            </div>
            <span className="comparison-icon">⟷</span>
            <div className="user-avatar-wrapper">
              <img 
                src={user2Data.avatar_url || 'https://via.placeholder.com/50'} 
                alt={user2Data.pseudo}
                className="user-avatar"
              />
              <span>{user2Data.pseudo}</span>
            </div>
          </div>
          <h2>Recommandations Communes</h2>
        </div>
      );
    }
    
    return <h2>Chargement...</h2>;
  };

  return (
    <div className="my-recommendations">
      {renderTitle()}
      {currentStep && (
        <div className="recommendation-step">
          <h3>{currentStep}</h3>
        </div>
      )}
      {loading ? (
        <div className="loading-spinner">
          <FaSpinner className="spinner-icon" />
        </div>
      ) : (
        recommendations.length > 0 ? (
          <GridListView 
            items={recommendations}
            handleScoring={handleScoring}
            addToList={addToList}
            onListUpdate={fetchInitialData}
          />
        ) : (
          <p>No recommendations available</p>
        )
      )}
    </div>
  );
}

export default MyReco;
