// src/components/BottomNavBar.js
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaHome, FaSearch, FaFilm, FaUsers, FaUser, FaMagic, FaRegListAlt, FaRandom } from 'react-icons/fa';
import { checkUserNotifications } from '../utils/api';
import './BottomNavBar.css';

const BottomNavBar = () => {
  const [notificationCount, setNotificationCount] = useState(0);
  const location = useLocation();

  useEffect(() => {
    const fetchNotifications = async () => {
      const count = await checkUserNotifications();
      setNotificationCount(count);
    };

    // Appel initial
    fetchNotifications();

    // Mise en place d'un intervalle pour les vérifications régulières
    const interval = setInterval(fetchNotifications, 3000); // 30 secondes

    // Nettoyage de l'intervalle lors du démontage du composant
    return () => clearInterval(interval);
  }, []);

  return (
    <nav className="bottom-nav">
      <Link to="/" className={`nav-item ${location.pathname === '/' ? 'active' : ''}`}>
        <FaHome />
        <span>Menu</span>
      </Link>
      <Link to="/social" className={`nav-item ${location.pathname === '/social' ? 'active' : ''}`}>
        <FaUsers />
        <span>Social</span>
      </Link>

      <Link to="/tinder" className={`nav-item-middle ${location.pathname === '/tinder' ? 'active' : ''}`}>
        <FaRandom />
        <span>Score!</span>
      </Link>
      <Link to="/search" className={`nav-item ${location.pathname === '/search' ? 'active' : ''}`}>
        <FaSearch />
        <span>Search</span>
      </Link>
      <Link to="/my-profile" className={`nav-item ${location.pathname === '/my-profile' ? 'active' : ''}`}>
        <FaUser />
        <span>My Profil</span>
        {notificationCount > 0 && (
          <span className="notification-count-bar">{notificationCount}</span>
        )}
      </Link>
    </nav>
  );
};

export default BottomNavBar;