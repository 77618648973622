import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faHandshake, faSearch, faUserPlus, faUserMinus, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { getAllUsers, getCompatibility, addRemoveFollower, checkAuth, isHeFollowingMe } from '../utils/api';
import './MyProfileFriends.css';

function MyProfileFriends() {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userCompatibility, setUserCompatibility] = useState({});
  const [searchTerm, setSearchTerm] = useState('');
  const [filterFollowed, setFilterFollowed] = useState(false);
  const [followingBack, setFollowingBack] = useState({});

  useEffect(() => {
    const init = async () => {
      try {
        // Vérifier l'authentification avant tout
        const authStatus = await checkAuth();
        if (!authStatus.data) {
          return; // checkAuth redirigera automatiquement vers /login
        }

        // Le reste du code existant pour fetchUsers
        const response = await getAllUsers();
        const usersData = response;
        
        // Fetch compatibilities and following back status
        const [compatibilityResults, followingBackResults] = await Promise.all([
          Promise.all(
            usersData
              .filter(user => user.user_id !== localStorage.getItem('userId'))
              .map(async (user) => {
                const compatibility = await getCompatibility(user.user_id);
                return [user.user_id, compatibility];
              })
          ),
          Promise.all(
            usersData
              .filter(user => user.user_id !== localStorage.getItem('userId'))
              .map(async (user) => {
                const isFollowingBack = await isHeFollowingMe(user.user_id);
                return [user.user_id, isFollowingBack];
              })
          )
        ]);
        
        const compatibilityMap = Object.fromEntries(compatibilityResults);
        const followingBackMap = Object.fromEntries(followingBackResults);
        
        setUsers(usersData);
        setUserCompatibility(compatibilityMap);
        setFollowingBack(followingBackMap);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    init();
  }, []);

  const handleFollowToggle = async (userId) => {
    try {
      const user = users.find(u => u.user_id === userId);
      const action = user.is_follower ? 'remove' : 'add';
      const response = await addRemoveFollower(userId, action);
      
      if (response) {
        setUsers(users.map(u => 
          u.user_id === userId 
            ? { ...u, is_follower: !u.is_follower }
            : u
        ));

        const compatibility = await getCompatibility(userId);
        setUserCompatibility(prev => ({
          ...prev,
          [userId]: compatibility
        }));
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
    }
  };

  const filteredUsers = users
    .filter(user => user.user_id !== localStorage.getItem('userId'))
    .filter(user => user.pseudo.toLowerCase().includes(searchTerm.toLowerCase()))
    .filter(user => !filterFollowed || user.is_follower);

  return (
    <div className="friends-page">
      <div className="friends-header">
        <button className="back-button" onClick={() => navigate('/my-profile')}>
          <FontAwesomeIcon icon={faArrowLeft} size="lg" />
          <span>Back to Profile</span>
        </button>
        <h2>Manage Your Network</h2>
      </div>

      <div className="friends-controls">
        <div className="search-bar">
          <FontAwesomeIcon icon={faSearch} />
          <input
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <label className="filter-toggle">
          <input
            type="checkbox"
            checked={filterFollowed}
            onChange={(e) => setFilterFollowed(e.target.checked)}
          />
          Show only followed accounts
        </label>
      </div>

      <div className="users-grid">
        {filteredUsers.map(user => (
          <div key={user.user_id} className="user-card">
            <img 
              src={user.avatar_url || 'https://via.placeholder.com/150'} 
              alt={user.pseudo}
              className="user-avatar"
              onClick={() => navigate(`/user/${user.user_id}`)}
            />
            <div className="user-info">
              <h3>{user.pseudo}</h3>
              <div className="compatibility-score-friends-profile">
                <FontAwesomeIcon icon={faHandshake} />
                <span className={`score-${Math.floor(userCompatibility[user.user_id] / 33)}`}>
                  {userCompatibility[user.user_id]}%
                </span>
              </div>
              
              {user.is_follower && followingBack[user.user_id] && (
                <div className="mutual-follow">
                  <FontAwesomeIcon icon={faExchangeAlt} />
                  <span>Mutual</span>
                </div>
              )}
              
              {user.is_follower && !followingBack[user.user_id] && (
                <div className="not-following-back">
                  <span>Not following you</span>
                </div>
              )}

              {!user.is_follower && followingBack[user.user_id] && (
                <div className="follows-you">
                  <span>Follows you</span>
                </div>
              )}
            </div>
            <button 
              className={`follow-button ${user.is_follower ? 'following' : ''}`}
              onClick={() => handleFollowToggle(user.user_id)}
            >
              <FontAwesomeIcon icon={user.is_follower ? faUserMinus : faUserPlus} />
              {user.is_follower ? 'Unfollow' : 'Follow'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default MyProfileFriends;
