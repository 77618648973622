import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FaStar, FaHandshake, FaList, FaUserPlus, FaUserMinus } from 'react-icons/fa';
import GridListView from './GridListView';
import { getAllUserScoring, getMyList, getCompatibility, rateMovie, isHeFollowingMe, addRemoveFollower, amIFollowingHim } from '../utils/api';
import axios from 'axios';
import './MyProfil.css';
import ReactMarkdown from 'react-markdown';
import MyBadges from './MyBadges';

function UserProfile() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [userScoring, setUserScoring] = useState([]);
  const [userList, setUserList] = useState([]);
  const [compatibility, setCompatibility] = useState(null);
  const eventSourceRef = useRef(null);
  const [isFollowing, setIsFollowing] = useState(false);
  const [followsMe, setFollowsMe] = useState(false);

  useEffect(() => {
    const setupScoringEventSource = () => {
      const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
      
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }

      eventSourceRef.current = new EventSource(`${baseUrl}/api/get-all-users-scoring?user_id=${userId}`, { withCredentials: true });

      eventSourceRef.current.onopen = () => {
        console.log('Scoring SSE connection opened');
      };

      eventSourceRef.current.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.error) {
            console.error('SSE error:', data.error);
            return;
          }
          setUserScoring(currentScoring => {
            const exists = currentScoring.some(film => film.film_id === data.film_id);
            if (!exists) {
              return [...currentScoring, data];
            }
            return currentScoring;
          });
        } catch (error) {
          console.error('Error parsing scoring SSE data:', error);
        }
      };

      eventSourceRef.current.onerror = (error) => {
        console.error('Scoring EventSource failed:', error);
        eventSourceRef.current.close();
      };
    };

    const fetchData = async () => {
      try {
        const [
          profileResponse, 
          compatibilityScore,
          followingMeStatus,
          followingStatus
        ] = await Promise.all([
          axios.get(`/api/get-profile?user_id=${userId}`),
          getCompatibility(userId),
          isHeFollowingMe(userId),
          amIFollowingHim(userId)  // Changed this line
        ]);

        setUserData(profileResponse.data);
        setCompatibility(compatibilityScore);
        setFollowsMe(followingMeStatus);
        setIsFollowing(followingStatus);  // No need to access .data anymore
        setupScoringEventSource();
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();

    return () => {
      if (eventSourceRef.current) {
        console.log('Closing scoring SSE connection');
        eventSourceRef.current.close();
      }
    };
  }, [userId]);

  const isInList = (filmId) => {
    return userList.some(item => item.film_id === filmId);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await rateMovie(filmId, mediaType, score, comments);
      const scoringData = await getAllUserScoring(userId);
      setUserScoring(scoringData);
    } catch (error) {
      console.error('Error scoring movie:', error);
    }
  };

  const handleListUpdate = async () => {
    try {
      const [updatedScoring, updatedList] = await Promise.all([
        getAllUserScoring(userId),
        getMyList()
      ]);
      setUserScoring(updatedScoring);
      setUserList(updatedList.data || []);
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const handleCommonRecommendations = () => {
    navigate(`/recommendations?users=${userId}`);
  };

  const handleFollowToggle = async () => {
    try {
      const action = isFollowing ? 'remove' : 'add';
      const response = await addRemoveFollower(userId, action);
      
      if (response) {
        setIsFollowing(!isFollowing);
        // Refresh compatibility after following/unfollowing
        const newCompatibility = await getCompatibility(userId);
        setCompatibility(newCompatibility);
      }
    } catch (error) {
      console.error('Error toggling follow:', error);
    }
  };

  if (!userData) return <div className="profile-page"><h2>Loading profile...</h2></div>;

  return (
    <div className="profile-page">
      <div className="profile-header">
        <img 
          src={userData.avatar_url || 'https://via.placeholder.com/150'} 
          alt="Profile" 
          className="profile-avatar"
        />
        <h2>{userData.pseudo}</h2>
        
        <div className="profile-actions">
          {followsMe && (
            <div className="follows-you-badge">
              Follows you
            </div>
          )}
          <button 
            className={`follow-button ${isFollowing ? 'following' : ''}`}
            onClick={handleFollowToggle}
          >
            {isFollowing ? (
              <>
                <FaUserMinus /> Unfollow
              </>
            ) : (
              <>
                <FaUserPlus /> Follow
              </>
            )}
          </button>
        </div>

        {compatibility !== null && (
          <>
            <div className="compatibility-indicator">
              <FaHandshake className="compatibility-icon" />
              <span className="compatibility-label">Compatibilité</span>
              <span 
                className="compatibility-score"
                style={{
                  color: compatibility > 60 ? '#4CAF50' : 
                        compatibility > 40 ? '#FFA726' : '#F44336'
                }}
              >
                {compatibility}%
              </span>
            </div>
            <button 
              className="common-recommendations-button"
              onClick={handleCommonRecommendations}
            >
              <FaList /> Voir nos recommandations communes
            </button>
          </>
        )}
      </div>
      
      <div className="profile-stats">
        <div className="stat-item">
          <span className="stat-value">{userData.rated_movie_count}</span>
          <span className="stat-label">Movies</span>
        </div>
        <div className="stat-item">
          <span className="stat-value">{userData.rated_tv_count}</span>
          <span className="stat-label">TV Shows</span>
        </div>
        <div className="stat-item">
              <span className="stat-value">{userData.badges_number}</span>
              <span className="stat-label">Badges</span>
        </div>
      </div>

      <div className="profile-bio">
        <h3>About</h3>
        <p>{userData.bio || 'No bio yet'}</p>
      </div>
      
      <MyBadges badges={userData?.badges} />
      
      <div className="profile-bio">
        <h3>Profile Analysis (based on scorings)</h3>
            
        <ReactMarkdown skipHtml={true} breaks={true}>
        {userData.profile || 'No profile analysis available'}</ReactMarkdown>
      </div>

      <div className="user-ratings-section">
        <h3><FaStar /> Films & Séries Notés</h3>
      </div>
        {userScoring.length > 0 ? (
          <GridListView
          items={[...userScoring]}
          isInList={isInList}
            handleScoring={handleScoring}
            onListUpdate={handleListUpdate}
            isReadOnly={false}  // Changed to false to enable buttons
          />
        ) : (
          <p>Aucun film ou série noté pour le moment</p>
        )}
    </div>
  );
}

export default UserProfile;