import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaSpinner } from 'react-icons/fa';
import Select from 'react-select';
import GridListView from './GridListView';
import { getMyList, addToList as apiAddToList, removeFromList as apiRemoveFromList } from '../utils/api';
import './MyList.css';

function MyList() {
  const navigate = useNavigate();
  const [myList, setMyList] = useState([]);
  const [loading, setLoading] = useState(true);
  const eventSourceRef = useRef(null);
  const [selectedScores, setSelectedScores] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [filterOptions, setFilterOptions] = useState({
    scores: [],
    types: [],
    platforms: [],
    genres: []
  });

  const updateFilterOptions = (currentList) => {
    const scores = [...new Set(currentList.map(item => item.score))]
      .filter(Boolean)
      .map(score => ({ value: score, label: `${score}/5` }));

    const types = [...new Set(currentList.map(item => item.media_type))]
      .map(type => ({ 
        value: type, 
        label: type === 'movie' ? 'Movie' : 'TV Show' 
      }));

    const platforms = [...new Set(currentList.flatMap(item => item.platforms || []))]
      .map(platform => ({ value: platform, label: platform }));

    const genres = [...new Set(currentList.flatMap(item => item.genres || []))]
      .map(genre => ({ value: genre, label: genre }));

    setFilterOptions({ scores, types, platforms, genres });
  };

  const setupEventSource = () => {
    setLoading(true);
    setMyList([]); // Reset list when reconnecting
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
    
    // Fermer l'ancienne connexion si elle existe
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    // console.log('Connecting to SSE...'); // Debug log
    eventSourceRef.current = new EventSource(`${baseUrl}/api/get-all-my-rated-items`, { withCredentials: true });

    eventSourceRef.current.onopen = () => {
      // console.log('SSE connection opened'); // Debug log
    };

    eventSourceRef.current.onmessage = (event) => {
      try {
        const item = JSON.parse(event.data);
        if (item.error) {
          console.error('SSE error:', item.error);
          return;
        }
        setMyList(currentList => {
          const exists = currentList.some(listItem => listItem.film_id === item.film_id);
          if (!exists) {
            const newList = [...currentList, item];
            updateFilterOptions(newList); // Mise à jour des filtres avec la nouvelle liste
            return newList;
          }
          return currentList;
        });
        setLoading(false);
      } catch (error) {
        console.error('Error parsing SSE data:', error);
      }
    };

    eventSourceRef.current.onerror = (error) => {
      console.error('EventSource failed:', error);
      eventSourceRef.current.close();
      setLoading(false);
    };
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        }
        setupEventSource();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();

    return () => {
      if (eventSourceRef.current) {
        // console.log('Closing SSE connection'); // Debug log
        eventSourceRef.current.close();
      }
    };
  }, [navigate]);

  const isInList = (filmId) => {
    return myList.some(item => item.film_id === filmId);
  };

  const handleScoring = async (filmId, mediaType, score, comments) => {
    try {
      await axios.post('/api/rate_movie_ajax', {
        film_id: filmId,
        media_type: mediaType,
        score: score,
        comments: comments || "",
      });
      
      // Reset list and reconnect SSE after rating
      setMyList([]);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      setupEventSource();
    } catch (error) {
      console.error('Error rating movie:', error);
    }
  };

  const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
    try {
      await apiAddToList(filmId, mediaType, title, posterPath, genreIds);
      // Reset list and reconnect SSE after adding
      setMyList([]);
      if (eventSourceRef.current) {
        eventSourceRef.current.close();
      }
      setupEventSource();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  const removeFromList = async (filmId) => {
    try {
      await apiRemoveFromList(filmId);
      setMyList(prevList => prevList.filter(item => item.film_id !== filmId));
    } catch (error) {
      console.error('Error removing from list:', error);
    }
  };

  const getFilteredList = () => {
    return myList.filter(item => {
      const matchesScore = selectedScores.length === 0 || 
        selectedScores.some(s => s.value === item.score);
      const matchesType = selectedTypes.length === 0 || 
        selectedTypes.some(t => t.value === item.media_type);
      const matchesPlatform = selectedPlatforms.length === 0 || 
        selectedPlatforms.some(p => item.platforms?.includes(p.value));
      const matchesGenre = selectedGenres.length === 0 || 
        selectedGenres.some(g => item.genres?.includes(g.value));
      
      return matchesScore && matchesType && matchesPlatform && matchesGenre;
    });
  };

  return (
    <div className="my-list">
      <h2>My List</h2>
      {loading ? (
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
        </div>
      ) : (
        myList.length > 0 ? (
          <>
            <div className="filters-container">
              <div className="filter">
                <label>Score</label>
                <Select
                  isMulti
                  options={filterOptions.scores}
                  value={selectedScores}
                  onChange={setSelectedScores}
                  placeholder="Filter by score"
                />
              </div>
              <div className="filter">
                <label>Type</label>
                <Select
                  isMulti
                  options={filterOptions.types}
                  value={selectedTypes}
                  onChange={setSelectedTypes}
                  placeholder="Filter by type"
                />
              </div>
              <div className="filter">
                <label>Platforms</label>
                <Select
                  isMulti
                  options={filterOptions.platforms}
                  value={selectedPlatforms}
                  onChange={setSelectedPlatforms}
                  placeholder="Filter by platform"
                />
              </div>
              <div className="filter">
                <label>Genres</label>
                <Select
                  isMulti
                  options={filterOptions.genres}
                  value={selectedGenres}
                  onChange={setSelectedGenres}
                  placeholder="Filter by genre"
                />
              </div>
            </div>
            <p>Found {getFilteredList().length} items</p>
            <GridListView 
              items={getFilteredList()}
              isInList={isInList}
              addToList={addToList}
              handleScoring={handleScoring}
              onListUpdate={setupEventSource}
            />
          </>
        ) : (
          <p>Your list is empty</p>
        )
      )}
    </div>
  );
}

export default MyList;