import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function MovieDetails() {
  const { mediaType, id } = useParams();
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axios.get(`/api/fetch_details_from_id?media_type=${mediaType}&id=${id}`);
        setDetails(response.data);
      } catch (error) {
        console.error('There was an error fetching the details!', error);
      }
    };

    fetchDetails();
  }, [id, mediaType]);

  if (!details) {
    return <p>Loading details...</p>;
  }

  return (
    <div className="details-page">
      <h2>{details.title || details.name}</h2>
      {details.poster_path && (
        <img 
          src={`https://image.tmdb.org/t/p/original${details.poster_path}`}
          alt={details.title || details.name}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/placeholder-image.jpg'; // Add a placeholder image
          }}
        />
      )}
      {details.overview && <p>{details.overview}</p>}
      {(details.release_date || details.first_air_date) && (
        <p>Release Date: {details.release_date || details.first_air_date}</p>
      )}
      {details.vote_average && <p>Rating: {details.vote_average}</p>}
    </div>
  );
}

export default MovieDetails;