import React, { useEffect, useState } from 'react';
import './BlurayComponent.css';
import { renderStars } from '../utils/helpers';
import { getItemRatings, isInMyList, addToList, removeFromList, getUserRating, rateMovie } from '../utils/api';
import { getScoreEmojiOnly } from '../utils/helpers';
import Modal from './Modal';
import Notation from './Notation';
import FilmDetails from './FilmDetails';

const isReadOnly = false;
const BlurayComponent = ({ item, onListUpdate, handleScoring, showScorings, showButtons }) => {
    const [ratings, setRatings] = useState([]);
    const [isInList, setIsInList] = useState(false);
    const [isNotationModalOpen, setIsNotationModalOpen] = useState(false);
    const [userRating, setUserRating] = useState(null);
    const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

    const handleMyListClick = async () => {
        try {
            if (isInList) {
                await removeFromList(item.film_id);
            } else {
                await addToList(
                    item.film_id,
                    item.media_type,
                    item.title,
                    item.poster_path,
                    item.genre_ids
                );
            }
            // Mise à jour de l'état du drapeau
            setIsInList(!isInList);
            // Si onListUpdate existe, on l'appelle pour mettre à jour la vue parent
            if (onListUpdate) onListUpdate();
        } catch (error) {
            console.error('Error updating list:', error);
        }
    };

    const handleScoreSubmit = async ({ score, comment }) => {
        try {
            await rateMovie(item.film_id, item.media_type, score, comment);
            if (handleScoring) {
                handleScoring(item, score, comment);
            }
            setUserRating(score);
        } catch (error) {
            console.error('Error submitting rating:', error);
        }
        setIsNotationModalOpen(false);
    };

    useEffect(() => {
        if (showScorings) {
            getItemRatings(item.film_id, item.media_type)
                .then(data => {
                    if (data) {
                        setRatings(data);
                    }
                })
                .catch(error => {
                    console.error('Error fetching ratings:', error);
                });
        }
    }, [showScorings, item.id, item.media_type]);

    useEffect(() => {
        // Vérifier si le film est dans la liste
        if (item.film_id && item.media_type) {
            isInMyList(item.film_id, item.media_type)
                .then(result => setIsInList(result))
                .catch(error => console.error('Error checking list status:', error));
        }
    }, [item.film_id, item.media_type]);

    useEffect(() => {
        // Récupérer le score de l'utilisateur
        if (item.film_id && item.media_type) {
            getUserRating(item.film_id, item.media_type)
                .then(rating => setUserRating(rating))
                .catch(error => console.error('Error fetching user rating:', error));
        }
    }, [item.film_id, item.media_type]);

    return (
        <>
      

        <div className="bluray-case-center">
        <div className="cover">
          <div className="top-bar">
            <div className="bluray-titre">{item.title} ({item.year})</div>
            <div className="bluray-logo">{item.media_type === 'tv' ? 'TV SHOW' : 'MOVIE'}™</div>
          </div>
          <div className="bluray-overlay-haut">
            <div className='genre'>
                <span className="director-tag">{item.director}</span>
              {item.genre_names && item.genre_names.map((genre, index) => (
                <span key={index} className="genre-tag">{genre}</span>
              ))}
            </div>
            <div className='streaming-platforms'>
              {item.streaming_plateforms && item.streaming_plateforms.map((platform, index) => (
                <span key={index} className="genre-tag">
                  {platform.name}
                </span>
              ))}
            </div>
          </div>
          <div className="cover-image" style={{
            backgroundImage:`linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
            url(https://image.tmdb.org/t/p/original${item.poster_path})`
          }}></div>
        </div>
        <div className="spine"></div>
      </div>


      {showButtons && (
  <div className="action-buttons">
    <button 
        className="square-button"
        onClick={handleMyListClick}
    >
      <span className="button-icon">{isInList ? '🚩' : '⚐'}</span>
      <span className="button-text">My List</span>
    </button>
    <button 
        className="square-button"
        onClick={() => setIsNotationModalOpen(true)}
    >
        <span className="button-icon">
            {userRating ? (
                   getScoreEmojiOnly(userRating)
            ) : (
                '⭐'
            )}
        </span>
        <span className="button-text">Score</span>
    </button>
    <button className="square-button" onClick={() => setIsInfoModalOpen(true)}>
      <span className="button-icon">▶️</span>
      <span className="button-text">Infos</span>
    </button>
    {/* <button className="square-button">
      <span className="button-icon">▶️</span>
      <span className="button-text">Trailers</span>
    </button> */}
  </div>
)}

<Modal 
    isOpen={isNotationModalOpen} 
    onClose={() => setIsNotationModalOpen(false)}
    title={`Rate "${item.title}"`}
    showCloseButton={false}
>
    <Notation
        onSubmit={handleScoreSubmit}
        showTooltip={true}
    />
</Modal>

<Modal 
    isOpen={isInfoModalOpen} 
    onClose={() => setIsInfoModalOpen(false)}
    title={`${item.title}`}
    showCloseButton={true}
>
    <FilmDetails 
        mediaType={item.media_type} 
        id={item.film_id}
    />
</Modal>

{showScorings && (
  <div>
    <div className="all-ratings">
      {ratings.map((rating, idx) => (
        <div key={idx} className="rating-full">
          <div className="rating-header">
            <img src={rating.avatar_url} alt={`${rating.pseudo}'s avatar`} className="avatar-small" />
            <span className="rating-name">{rating.pseudo}</span>
            <span className="rating-stars">{renderStars(rating.score)}</span>
          </div>
          <div className="rating-content">
            {rating.comments && <p className="rating-comments">{rating.comments}</p>}
          </div>
        </div>
      ))}
    </div>
  </div>
)}




</>


    );
};

export default BlurayComponent;
