// src/components/Home.js

import React, { useState, useEffect } from 'react';
import { FaSpinner, FaSignInAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import GridListView from './GridListView';
import { getActivity, addToList, getMyList, rateMovie, checkAuth } from '../utils/api';
import { isInList, renderStars } from '../utils/helpers';
import './Home.css';

function Home() {
  const [activity, setActivity] = useState([]);
  const [list, setList] = useState([]);
  const [currentActivityIndex, setCurrentActivityIndex] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isAuth, setIsAuth] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [authResponse, activityResponse, listResponse] = await Promise.all([
          checkAuth(),
          getActivity(),
          getMyList()
        ]);
        
        // Utilisez directement la valeur booléenne
        setIsAuth(authResponse.data);
        setActivity(activityResponse.data);
        setList(listResponse.data || []);
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsAuth(false);
        setList([]);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  const handleScroll = (direction) => {
    if (direction === 'up' && currentActivityIndex < activity.length - 1) {
      setCurrentActivityIndex(prev => prev + 1);
    } else if (direction === 'down' && currentActivityIndex > 0) {
      setCurrentActivityIndex(prev => prev - 1);
    }
  };

  const handleScoring = async (filmId, mediaType, score, comments='') => {
    try {
      await rateMovie(
        filmId,
        mediaType,
        score,
        comments
      );
      
      // Rafraîchir l'activité immédiatement après le vote
      const activityResponse = await getActivity();
      setActivity(activityResponse.data);
      refreshList();
    } catch (error) {
      console.error('Error scoring movie:', error);
      // Vous pouvez ajouter ici une notification d'erreur si vous le souhaitez
    } finally {
      //setLoading(false);
    }
  };

  const refreshList = async () => {
    try {
      const listResponse = await getMyList();
      setList(listResponse.data || []);
    } catch (error) {
      console.error('Error refreshing list:', error);
    }
  };

  return (
    <div className="home">
        {!loading && !isAuth && (
          <div className="home-header">
            <Link to="/login" className="login-button">
              <FaSignInAlt />
              Se connecter
            </Link>      
          </div>
        )}
         {!loading && isAuth && (
          <h2>Fil d'actualité</h2>
        )}
      
      {loading ? (
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
        </div>
      ) : (
        <GridListView 
          items={activity}
          currentIndex={currentActivityIndex}
          isInList={(filmId) => isInList(list, filmId)}
          addToList={addToList}
          handleScoring={handleScoring}
          onListUpdate={refreshList}
        />
      )}
    </div>
  );
}

export default Home;