import React, { useState, useEffect } from 'react';
import { FaStar, FaPlay, FaInfo } from 'react-icons/fa';
import { renderStars, getScoreEmoji } from '../utils/helpers';
import { addToList, removeFromList, getStreamingPlatforms, getMovieDetails, getTrailer, getUserRating } from '../utils/api';
import './GridListView.css';

function ItemButtons({ item, isInList, onListUpdate, handleScoring }) {
  const [showTrailerModal, setShowTrailerModal] = useState(false);
  const [showScoringModal, setShowScoringModal] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [movieDetails, setMovieDetails] = useState({});
  const [comment, setComment] = useState('');  // Ajout de l'état pour le commentaire
  const [trailerData, setTrailerData] = useState(null);
  const [userRating, setUserRating] = useState(null);

  const getItemId = (item) => {
    return item.film_id || item.id;
  };

  const handleListAction = async () => {
    const id = getItemId(item);
    try {
      if (isInList(id)) {
        await removeFromList(id);
      } else {
        await addToList(
          id,
          item.media_type,
          item.title,
          item.poster_path,
          item.genre_ids
        );
      }
      onListUpdate && onListUpdate();
    } catch (error) {
      console.error('Error updating list:', error);
    }
  };

  useEffect(() => {
    if (showTrailerModal) {
      setLoading(true);
      console.log('Fetching data for:', item.media_type, getItemId(item)); // Debug log
      
      Promise.all([
        getStreamingPlatforms(item.media_type, getItemId(item)),
        getTrailer(item.media_type, getItemId(item))
      ])
        .then(([platformsData, trailerData]) => {
          console.log('Received platforms:', platformsData); // Debug log
          setPlatforms(platformsData || []);
          setTrailerData(trailerData);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setPlatforms([]);
          setLoading(false);
        });
    }
  }, [showTrailerModal, item]);

  useEffect(() => {
    if (showSummaryModal) {
      setLoading(true);
      getMovieDetails(item.media_type, getItemId(item))
        .then(response => {
          setMovieDetails(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching movie details:', error);
          setLoading(false);
        });
    }
  }, [showSummaryModal, item]);

  useEffect(() => {
    if (showTrailerModal || showScoringModal || showSummaryModal) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
    
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [showTrailerModal, showScoringModal, showSummaryModal]);

  useEffect(() => {
    const fetchUserRating = async () => {
      const filmId = getItemId(item);
      const rating = await getUserRating(filmId);
      setUserRating(rating);
    };
    fetchUserRating();
  }, [item]);

  const handleScoreSubmit = async (score) => {
    const filmId = String(item.film_id || item.id);
    await handleScoring(filmId, item.media_type, score, comment);  // Ajout du commentaire
    setComment('');  // Reset le commentaire
    setShowScoringModal(false);
  };

  return (
    <>
      <div className="grid-list-buttons">
        <button 
          className={`action-button ${isInList(getItemId(item)) ? 'active' : ''}`}
          onClick={handleListAction}
        >
          <span className="button-icon">{isInList(getItemId(item)) ? '🚩' : '⚐'}</span>
          <span className="button-label">Ma Liste</span>
        </button>
        
        <button 
          className={`action-button ${userRating ? 'rated' : ''}`}
          onClick={() => setShowScoringModal(true)}
        >
          <span className="button-icon">
            <FaStar style={{ color: userRating ? '#ff4444' : 'white' }} />
            {userRating && (
              <span className="rating-value" style={{ 
                color: 'white',
                fontWeight: 'bold',
                marginLeft: '4px'
              }}>
                {userRating}
              </span>
            )}
          </span>
          <span className="button-label">Noter</span>
        </button>

        <button 
          className="action-button"
          onClick={() => setShowTrailerModal(true)}
        >
          <span className="button-icon"><FaPlay /></span>
          <span className="button-label">Trailers</span>
        </button>

        <button 
          className="action-button"
          onClick={() => setShowSummaryModal(true)}
        >
          <span className="button-icon"><FaInfo /></span>
          <span className="button-label">Infos</span>
        </button>
      </div>

      {showTrailerModal && (
        <div className="modal-overlay" onClick={() => setShowTrailerModal(false)}>
          <div className="modal-content platforms-modal" onClick={e => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowTrailerModal(false)}>×</button>            
            {loading ? (
              <div className="loading">Chargement...</div>
            ) : (
              <div className="platforms-container">
                <div className="platforms-list">
                  {platforms.length > 0 ? (
                    platforms.map((platform, index) => (
                      <div key={index} className="platform-item">
                        <img src={platform.logo} alt={platform.name} />
                        <span>{platform.name}</span>
                      </div>
                    ))
                  ) : (
                    <p>Non disponible en streaming pour le moment</p>
                  )}
                </div>

                {trailerData && typeof trailerData === 'string' && (
                  <div className="trailer-container">
                    <iframe
                      width="100%"
                      height="250"
                      src={trailerData.replace('youtube.com/watch?v=', 'youtube.com/embed/')}
                      title="Trailer"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
              </div>
            )}
            <button className="close-modal-button" onClick={() => setShowTrailerModal(false)}>
              Fermer
            </button>
          </div>
        </div>
      )}

      {showScoringModal && (
        <div className="modal-overlay" onClick={() => setShowScoringModal(false)}>
          <div className="modal-content scoring-modal" onClick={e => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowScoringModal(false)}>×</button>
            <span className="modal-title">Note</span>
            <textarea
              className="comment-input"
              placeholder="Commentaires"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              rows={2}
            />
            <div className="scoring-options">
              {[5, 4, 3, 2, 1].map((score) => (
                <button
                  key={score}
                  className="score-button"
                  onClick={() => handleScoreSubmit(score)}
                >
                  <span className="score-emoji">{getScoreEmoji(score).split(' ')[0]}</span>
                  <span className="score-text">{getScoreEmoji(score).split(' ').slice(1).join(' ')}</span>
                </button>
              ))}
            </div>
            <button className="close-modal-button" onClick={() => setShowScoringModal(false)}>
              Fermer
            </button>
          </div>
        </div>
      )}

      {showSummaryModal && (
        <div className="modal-overlay" onClick={() => setShowSummaryModal(false)}>
          <div className="modal-content summary-modal" onClick={e => e.stopPropagation()}>
            <button className="modal-close" onClick={() => setShowSummaryModal(false)}>×</button>
            <span className="modal-title">{movieDetails.original_title || movieDetails.original_name} </span>
            <div className="summary-details">
              <p><strong></strong>{movieDetails.overview || 'Non disponible'}</p>
              <p><strong></strong> {movieDetails.release_date || movieDetails.first_air_date || 'Non disponible'}</p>
              <p><strong></strong> {movieDetails.runtime || movieDetails.episode_run_time?.[0] || 'Non disponible'} minutes</p>
            </div>
            <div className="platforms-list">
              {loading ? (
                <div className="loading">Chargement...</div>
              ) : platforms.length > 0 ? (
                platforms.map((platform, index) => (
                  <div key={index} className="platform-item">
                    <img src={platform.logo} alt={platform.name} />
                    <span>{platform.name}</span>
                  </div>
                ))
              ) : (
                <p>Non disponible en streaming pour le moment</p>
              )}
            </div>
            <button className="close-modal-button" onClick={() => setShowSummaryModal(false)}>
              Fermer
            </button>
          </div>
        </div>
      )}
    </>
  );
}

function GridListView({ items, currentIndex, onClose, onScroll, isInList, handleScoring, onListUpdate, isReadOnly = false }) {
  const truncateComment = (comment, maxLength = 100) => {
    if (!comment) return '';
    return comment.length > maxLength ? `${comment.substring(0, maxLength)}...` : comment;
  };

  return (
    <div className="grid-container activity-grid">
      {items.map((item, index) => (
        <div key={index} className="grid-item" 
          style={{ 
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)), 
                     url(https://image.tmdb.org/t/p/original${item.poster_path})` 
          }}>
          <div className="grid-content-header">
            <h3 className="item-title">{item.title}</h3>
            <p className='media-type'>{item.media_type === 'movie' ? 'Film' : 'Série'}</p>
            <div className='genre'>
              {item.genre_names && item.genre_names.map((genre, index) => (
                <span key={index} className="genre-tag">{genre}</span>
              ))}
            </div>
          </div>
          
          {!isReadOnly && (
            <ItemButtons 
              item={item}
              isInList={isInList}
              onListUpdate={onListUpdate}
              handleScoring={handleScoring}
            />
          )}

          <div className="item-overlay">
            <div className="ratings-preview">
              {item.ratings && item.ratings.slice(0, 10).map((rating, idx) => (
                <div key={idx} className="rating-compact">
                  <img src={rating.avatar_url} alt={`${rating.pseudo}'s avatar`} className="avatar-small" />
                  <div className="rating-details">
                    <a href={`/user/${rating.user_id}`} className='rating-pseudo'>
                        <span className="rating-pseudo">{rating.pseudo}</span>
                        <div className="rating-score-comment">
                          {renderStars(rating.score)}
                          {rating.comments && (
                            <span className="rating-comment">{truncateComment(rating.comments, 200)}</span>
                          )}
                        </div>
                      </a>
                    </div>
                </div>
              ))}
              {item.ratings && item.ratings.length > 10 && (
                <span className="more-ratings">+{item.ratings.length - 10} more</span>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default GridListView;