import axios from 'axios';

// Utilitaire pour générer un ID unique pour chaque requête
const generateRequestId = () => Math.random().toString(36).substring(7);

// Fonction utilitaire pour formater les logs
const formatLogMessage = (type, requestId, message, data) => {
  const timestamp = new Date().toISOString();
  console.log(`[${timestamp}] [${type}] [ReqID: ${requestId}] ${message}`, data || '');
};

// Configuration globale d'axios avec support explicite des cookies
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// Intercepteur pour logger les requêtes
axios.interceptors.request.use(
  config => {
    const requestId = generateRequestId();
    config.requestId = requestId;
    
    // formatLogMessage('REQUEST', requestId, 'API Call', {
    //   method: config.method?.toUpperCase(),
    //   url: config.url,
    //   headers: config.headers,
    //   data: config.data,
    //   params: config.params
    // });
    
    config.withCredentials = true;
    return config;
  },
  error => {
    formatLogMessage('REQUEST_ERROR', 'N/A', 'Request Configuration Error', error);
    return Promise.reject(error);
  }
);

// Intercepteur pour logger les réponses
axios.interceptors.response.use(
  response => {
    const requestId = response.config.requestId;
    // formatLogMessage('RESPONSE', requestId, 'API Response Success', {
    //   status: response.status,
    //   statusText: response.statusText,
    //   data: response.data,
    //   headers: response.headers
    // });
    return response;
  },
  error => {
    const requestId = error.config?.requestId || 'N/A';
    formatLogMessage('RESPONSE_ERROR', requestId, 'API Response Error', {
      message: error.message,
      status: error.response?.status,
      data: error.response?.data,
      config: {
        url: error.config?.url,
        method: error.config?.method,
        data: error.config?.data
      }
    });

    if (error.response?.status === 401) {
      formatLogMessage('AUTH', requestId, 'Authentication Failed - Redirecting to login');
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

// Intercepteur pour vérifier les réponses
axios.interceptors.response.use(
  response => response,
  error => {
    console.error('API Error:', error);
    return Promise.reject(error);
  }
);

// Configuration d'axios
axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL || '';

// Intercepteur pour gérer les erreurs de session
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      // Rediriger vers login si session expirée ou interdite
      window.location = '/login';
    }
    return Promise.reject(error);
  }
);

// Fonctions d'API

// Authentification
export const checkAuth = () => axios.get('/api/check-auth')
  .then(response => {
    // Assurez-vous que la réponse contient isAuthenticated
    return {
      data: response.data.isAuthenticated,
      user: response.data.user
    };
  })
  .catch(() => {
    return { data: false, user: null };
  });

export const login = (email, password) => axios.post('/api/login', { inputEmail: email, inputPassword: password });
export const register = (userData) => axios.post('/api/register', {
    inputEmail: userData.email,
    inputFirstname: userData.firstname,
    inputName: userData.name,
    inputPassword: userData.password,
    acceptCGV: userData.acceptCGV,
    captchaToken: userData.captchaToken
});
export const logout = () => axios.post('/api/logout');

// Fonction utilitaire pour vérifier l'authentification
const checkAuthBeforeRequest = async () => {
  try {
    await checkAuth();
    return true;
  } catch (error) {
    return false;
  }
};

// Activité
export const getActivity = (onReceiveFilm) => {
  const eventSource = new EventSource(`${axios.defaults.baseURL}/api/get-activity`);
  
  eventSource.onmessage = (event) => {
    try {
      //console.log('SSE raw data received:', event.data); // Debug log
      const data = JSON.parse(event.data);
      //console.log('SSE parsed data:', data); // Debug log
      if (data && onReceiveFilm) {
        onReceiveFilm(data);
      }
    } catch (error) {
      console.error('Error parsing SSE data:', error);
    }
  };

  eventSource.onerror = (error) => {
    console.error('EventSource error:', error);
    if (eventSource.readyState === EventSource.CLOSED) {
      console.log('SSE connection closed');
    }
  };

  eventSource.onopen = () => {
    console.log('SSE connection opened');
  };

  return () => {
    console.log('Closing SSE connection');
    eventSource.close();
  };
};

// Score propositions
export const getScorePropositions = () => {
  // console.log('Calling getScorePropositions'); // Ajout de log
  return axios.get('/api/score-proposition-batch')
    .then(response => {
      // console.log('API response received:', response); // Ajout de log
      if (!response.data) {
        throw new Error('No propositions received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching propositions:', error);
      throw error;
    });
};

// Liste personnelle
export const getMyList = async () => {
  const isAuth = await checkAuthBeforeRequest();
  if (!isAuth) {
    return Promise.resolve({ data: [] });
  }
  return axios.get('/api/get-my-list');
};

export const getAllMyMovies = async () => {
  const isAuth = await checkAuthBeforeRequest();
  if (!isAuth) {
    return Promise.resolve({ data: [] });
  }
  return axios.get('/api/get-all-my-rated-items');
};

export const addToList = async (filmId, mediaType, title, posterPath, genreIds) => {
  const isAuth = await checkAuthBeforeRequest();
  if (!isAuth) {
    return Promise.reject(new Error('Authentication required'));
  }
  return axios.post('/api/add-to-list', { 
    film_id: filmId, 
    media_type: mediaType, 
    title, 
    poster_path: posterPath, 
    genre_ids: genreIds 
  });
};

export const removeFromList = (filmId) => axios.post('/api/remove-from-list', { film_id: filmId });

export const isInMyList = async (filmId, mediaType) => {
  try {
    const response = await axios.get(`/api/is_in_my_list?film_id=${filmId}&media_type=${mediaType}`);
    return response.data;
  } catch (error) {
    console.error('Error checking if film is in list:', error);
    return false;
  }
};

// Liste non vue
export const addToUnseenList = async (filmId, mediaType, boycott) => {
  const isAuth = await checkAuthBeforeRequest();
  if (!isAuth) {
    return Promise.reject(new Error('Authentication required'));
  }
  return axios.post('/api/add-to-unseen_list', { 
    film_id: filmId, 
    media_type: mediaType,
    boycott
  });
};

// Recherche
export const searchMovies = (query) => axios.get(`/api/search?query=${query}`);

// Détails du film/série
export const getMovieDetails = (mediaType, id) => 
  axios.get(`/api/fetch_details_from_id?media_type=${mediaType}&id=${id}`)
    .then(response => {
      //console.log('Streaming platforms response:', response.data); // Ajout de log
      if (response.data === undefined) {
        return [];
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching streaming platforms:', error);
      return [];
    });



// Notation
export const rateMovie = (filmId, mediaType, score, comments) => 
  axios.post('/api/rate_movie_ajax', { film_id: filmId, media_type: mediaType, score: score, comments: comments });

// Profil utilisateur
export const getProfile = () => axios.get('/api/get-profile');
export const updateProfile = (formData) => axios.post('/api/update-profile', formData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// Gestion des utilisateurs
export const getAllUsers = () => 
  axios.get('/api/get-all-users')
    .then(response => {
      if (!response.data) {
        throw new Error('No users data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching users:', error);
      throw error;
    });

// Streaming platforms
export const getStreamingPlatforms = (mediaType, id) => 
  axios.get(`/api/get_streaming_platforms?media_type=${mediaType}&id=${id}`)
    .then(response => {
      //console.log('Streaming platforms response:', response.data); // Ajout de log
      if (response.data === undefined) {
        return [];
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching streaming platforms:', error);
      return [];
    });

// Recommendations
export const getRecommendations = () => axios.get('/api/get-recommendations')
  .then(response => {
    if (!response.data) {
      throw new Error('No recommendations data received');
    }
    return response.data;
  })
  .catch(error => {
    console.error('Error fetching recommendations:', error);
    throw error;
  });

// Get common recommendations between two users
export const getCommonRecommendations = (user1Id, user2Id) => 
  axios.get(`/api/get-common-recommendations?user_id_1=${user1Id}&user_id_2=${user2Id}`)
    .then(response => {
      if (!response.data) {
        throw new Error('No recommendations data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching common recommendations:', error);
      throw error;
    });

// Gestion des followers
export const toggleFollow = (userId) => axios.post('/api/toggle-follow', { userId });

// Gestion des followers - Nouvelle fonction
export const addRemoveFollower = (followerId, action) => 
  axios.get(`/api/add-remove-follower?follower_id=${followerId}&action=${action}`)
    .then(response => {
      if (!response.data) {
        throw new Error('No follower update data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error updating follower:', error);
      throw error;
    });

// Check if user2 is following current user
export const isHeFollowingMe = (userId) => 
  axios.get(`/api/is-he-following-me?user2_id=${userId}`)
    .then(response => {
      if (response?.data?.following !== undefined) {
        return response.data.following;
      }
      return false;
    })
    .catch(error => {
      console.error('Error checking if user is following:', error);
      return false;
    });

// Check if current user follows user2
export const amIFollowingHim = (userId) => 
  axios.get(`/api/am-i-following-him?user2_id=${userId}`)
    .then(response => {
      if (response?.data?.following !== undefined) {
        return response.data.following;
      }
      return false;
    })
    .catch(error => {
      console.error('Error checking if following user:', error);
      return false;
    });

// Get number of followings
export const getFollowingsNumber = () => 
  axios.get('/api/get-followings-number')
    .then(response => {
      if (!response.data) {
        throw new Error('No followings data received');
      }
      return response.data.followings_number;
    })
    .catch(error => {
      console.error('Error fetching followings number:', error);
      return 0;
    });

// Trailer
export const getTrailer = (mediaType, id) => 
  axios.get(`/api/get-trailer?media_type=${mediaType}&id=${id}`)
    .then(response => {
      if (!response.data) {
        throw new Error('No trailer data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching trailer:', error);
      throw error;
    });

// Get user rating for a specific film
export const getUserRating = (filmId, mediaType) => 
  axios.get(`/api/get-user-rating?film_id=${filmId}&media_type=${mediaType}`)
    .then(response => {
      // Vérifier si la réponse et la propriété rating existent
      if (response?.data?.rating !== undefined) {
        return response.data.rating;
      }
      return null;
    })
    .catch(error => {
      console.error('Error fetching user rating:', error);
      return null;
    });



    // Get user rating for a specific film
export const getItemRatings = (filmId, mediaType) => 
  axios.get(`/api/get-item-ratings?film_id=${filmId}&media_type=${mediaType}`)
    .then(response => {
      // Vérifier si la réponse et la propriété rating existent
      if (response?.data !== undefined) {
        return response.data;
      }
      return null;
    })
    .catch(error => {
      console.error('Error fetching user rating:', error);
      return null;
    });



// Get all user ratings
export const getAllUserScoring = (userId = null) => {
  const params = userId ? { user_id: userId } : {};
  return axios.get('/api/get-all-users-scoring', { params })
    .then(response => {
      if (!response.data) {
        throw new Error('No user scoring data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching user scoring:', error);
      throw error;
    });
  }
// Fonction générique pour gérer les erreurs
export const handleApiError = (error) => {
  const requestId = error.config?.requestId || 'N/A';
  formatLogMessage('ERROR_HANDLER', requestId, 'Detailed API Error', {
    message: error.message,
    response: error.response ? {
      data: error.response.data,
      status: error.response.status,
      headers: error.response.headers
    } : null,
    request: error.request ? 'Request made but no response received' : null,
    config: error.config
  });
  return Promise.reject(error);
};

// Get compatibility between users
export const getCompatibility = (userId) => 
  axios.get(`/api/get-compatibility?user2_id=${userId}`, {
    withCredentials: true,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  })
    .then(response => {
      if (response.data === undefined) {
        throw new Error('No compatibility data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching compatibility:', error);
      if (error.response?.status === 0 || error.message.includes('CORS')) {
        console.error('CORS error detected');
      }
      return 0;
    });

// Get cast information
export const getCasting = (mediaType, id) => 
  axios.get(`/api/get-casting?movie_id=${id}&media_type=${mediaType}`)
    .then(response => {
      if (!response.data) {
        throw new Error('No casting data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching cast:', error);
      return [];
    });

// Get dashboard statistics
export const getMainDashboard = () => 
  axios.get('/api/get-main-dashbording')
    .then(response => {
      if (!response.data) {
        throw new Error('No dashboard data received');
      }
      return response.data;
    })
    .catch(error => {
      console.error('Error fetching dashboard stats:', error);
      throw error;
    });

// Like/Unlike comment
export const likeComment = async (commentId) => {
  try {
    const response = await axios.post('/api/like-comment', { comment_id: commentId });
    return response.data;
  } catch (error) {
    console.error('Error liking comment:', error);
    throw error;
  }
};

// Check user notifications
export const checkUserNotifications = () =>
  axios.post('/api/check-user-notifications')
    .then(response => {
      if (response?.data?.profile_notifications !== undefined) {
        return response.data.profile_notifications;
      }
      return 0;
    })
    .catch(error => {
      console.error('Error checking user notifications:', error);
      return 0;
    });

// Get movie title suggestions from description
export const askForMovieTitle = (description) => 
  axios.post('/api/ask-for-movie-title', 
    { query: description },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  )
    .then(response => {
      if (typeof response.data === 'object' && response.data.detail) {
        throw new Error(response.data.detail);
      }
      return response.data;
    })
    .catch(error => {
      const errorMessage = error.response?.data?.detail || error.message;
      console.error('Error getting movie title suggestions:', errorMessage);
      throw new Error(typeof errorMessage === 'object' ? JSON.stringify(errorMessage) : errorMessage);
    });

