// src/components/Home.js

import React, { useState, useEffect, useRef } from 'react';
import { FaSpinner, FaSignInAlt, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { checkAuth, getFollowingsNumber } from '../utils/api';
import FeedLineItem from './FeedLineItem';
import './Feed.css';

function Home() {
  const [activity, setActivity] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAuth, setIsAuth] = useState(false);
  const [followingsCount, setFollowingsCount] = useState(0);
  const eventSourceRef = useRef(null);
  const [searchFilter, setSearchFilter] = useState('');
  const [expandedGroups, setExpandedGroups] = useState(new Set());

  const setupEventSource = () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL || '';
    
    // Fermer l'ancienne connexion si elle existe
    if (eventSourceRef.current) {
      eventSourceRef.current.close();
    }

    //console.log('Connecting to activity SSE...'); // Debug log
    eventSourceRef.current = new EventSource(`${baseUrl}/api/get-activity`, { withCredentials: true });

    eventSourceRef.current.onopen = () => {
      //console.log('Activity SSE connection opened');
    };

    eventSourceRef.current.onmessage = (event) => {
      //console.log('Activity SSE message received:', event.data);
      try {
        const newFilm = JSON.parse(event.data);
        if (newFilm.error) {
          console.error('SSE error:', newFilm.error);
          return;
        }
        setActivity(currentActivity => {
          const exists = currentActivity.some(film => film.film_id === newFilm.film_id);
          if (!exists) {
            //console.log('Adding new activity:', newFilm.title);
            return [...currentActivity, newFilm]; // Ajout à la fin de la liste
          }
          return currentActivity;
        });
        setLoading(false);
      } catch (error) {
        console.error('Error parsing activity SSE data:', error);
      }
    };

    eventSourceRef.current.onerror = (error) => {
      console.error('Activity EventSource failed:', error);
      eventSourceRef.current.close();
      setLoading(false);
    };
  };

  useEffect(() => {
    const init = async () => {
      try {
        const authResponse = await checkAuth();
        setIsAuth(authResponse.data);
        
        if (authResponse.data) {
          const count = await getFollowingsNumber();
          setFollowingsCount(count);
        }
        
        setupEventSource();
      } catch (error) {
        console.error('Error initializing:', error);
        setIsAuth(false);
        setLoading(false);
      }
    };

    init();

    return () => {
      if (eventSourceRef.current) {
        //console.log('Closing activity SSE connection');
        eventSourceRef.current.close();
      }
    };
  }, []);

  const safeItems = Array.isArray(activity) ? activity : [];

  // Créer un tableau des pseudos uniques
  const uniquePseudos = [...new Set(safeItems.map(item => item?.pseudo).filter(Boolean))];
  
  const filteredItems = safeItems.filter(item => 
    !searchFilter || item?.pseudo === searchFilter
  );

  const groupConsecutiveItems = (items) => {
    const groups = [];
    let currentGroup = [];
    let currentUserId = null;

    items.forEach((item) => {
      if (currentUserId === item.user_id) {
        currentGroup.push(item);
      } else {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentGroup = [item];
        currentUserId = item.user_id;
      }
    });

    if (currentGroup.length > 0) {
      groups.push(currentGroup);
    }

    return groups;
  };

  const toggleGroup = (userId) => {
    setExpandedGroups(prev => {
      const newSet = new Set(prev);
      if (newSet.has(userId)) {
        newSet.delete(userId);
      } else {
        newSet.add(userId);
      }
      return newSet;
    });
  };

  const renderFeedItems = () => {
    const groups = groupConsecutiveItems(filteredItems);
    return groups.map((group, groupIndex) => {
      const userId = group[0].user_id;
      const username = group[0].pseudo;
      const isExpanded = expandedGroups.has(userId);
      const itemsToShow = isExpanded ? group : group.slice(0, 2);
      const hiddenCount = group.length - 2;

      return (
        <div key={`group-${groupIndex}`}>
          {itemsToShow.map((item, index) => (
            <FeedLineItem 
              item={item}
              key={`${groupIndex}-${index}`}
              username={item?.pseudo || 'Anonymous'}
              userAvatar={item.avatar_url}
              rating={item?.score || 0}
              moviePoster={`https://image.tmdb.org/t/p/original${item.poster_path}`}
              movieTitle={item.title}
              index={index}
              comments={item.comments}
              date={item.date}
              mediaType={item.media_type}
            />
          ))}
          {group.length > 2 && !isExpanded && (
            <div 
              className="expand-banner"
              onClick={() => toggleGroup(userId)}
            >
              + {hiddenCount} more items watched by {username}, click to expand
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className="home">
      <h3>Your Friend's Watching Activities</h3>
      
      {loading ? (
        <div className="loading-container">
          <FaSpinner className="loading-icon" />
        </div>
      ) : (
        <>
          <div className="manage-follows-container">
            <Link to="/my-profile-friends" className="manage-follows-button">
              <FaUserFriends /> Manage My {followingsCount} Followed Accounts
            </Link>
          </div>

          <div className="search-filter-container">
            <select
              value={searchFilter}
              onChange={(e) => setSearchFilter(e.target.value)}
              className="search-filter-select"
            >
              <option value="">All Friends</option>
              {uniquePseudos.map((pseudo, index) => (
                <option key={index} value={pseudo}>
                  {pseudo}
                </option>
              ))}
            </select>
          </div>

          {followingsCount === 0 ? (
            <div className="no-follows-message" style={{ textAlign: 'center', margin: '20px 0' }}>
              You are not following any accounts yet. Click the button above to start following people and see their activities.
            </div>
          ) : (
            <>
              {renderFeedItems()}
              <br />
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Home;