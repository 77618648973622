// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-icon {
  font-size: 5rem;
  animation: spin 1s linear infinite;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

.home {
min-height: 100vh;
padding-top: 10px;
background-color: #111;
color: white;
padding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */
}

.grid-content-header {
position: relative;
top: 15px; /* Ajusté pour laisser de l'espace pour les boutons en haut */
left: 20px;
right: 80px;
z-index: 10;
max-height: calc(50vh - 80px); /* Limite la hauteur à la moitié de l'écran moins la marge du haut */
overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/Search.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,kCAAkC;AACpC;;AAEA;AACA,KAAK,uBAAuB,EAAE;AAC9B,OAAO,yBAAyB,EAAE;AAClC;;AAEA;AACA,iBAAiB;AACjB,iBAAiB;AACjB,sBAAsB;AACtB,YAAY;AACZ,oBAAoB,EAAE,gEAAgE;AACtF;;AAEA;AACA,kBAAkB;AAClB,SAAS,EAAE,6DAA6D;AACxE,UAAU;AACV,WAAW;AACX,WAAW;AACX,6BAA6B,EAAE,oEAAoE;AACnG,gBAAgB;AAChB","sourcesContent":[".loading-container {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n}\n\n.loading-icon {\n  font-size: 5rem;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n0% { transform: rotate(0deg); }\n100% { transform: rotate(360deg); }\n}\n\n.home {\nmin-height: 100vh;\npadding-top: 10px;\nbackground-color: #111;\ncolor: white;\npadding-bottom: 10px; /* Augmenté pour éviter le chevauchement avec la barre de menu */\n}\n\n.grid-content-header {\nposition: relative;\ntop: 15px; /* Ajusté pour laisser de l'espace pour les boutons en haut */\nleft: 20px;\nright: 80px;\nz-index: 10;\nmax-height: calc(50vh - 80px); /* Limite la hauteur à la moitié de l'écran moins la marge du haut */\noverflow-y: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
