import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUsers, faStar, faWandMagicSparkles, faHandshake, faFilm } from '@fortawesome/free-solid-svg-icons';
import { getProfile, getFollowingsNumber } from '../utils/api';
import './MyProfil.css';
import ReactMarkdown from 'react-markdown';
import MyBadges from './MyBadges';
import { FaSpinner, FaSignInAlt, FaUserFriends } from 'react-icons/fa';
import { Link } from 'react-router-dom';


function MyProfile() {
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    pseudo: '',
    bio: '',
  });
  const [avatarFile, setAvatarFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [error, setError] = useState('');
  const [users, setUsers] = useState([]);
  const [followingsCount, setFollowingsCount] = useState(0);

  //const [userScoring, setUserScoring] = useState([]);
  //const [userList, setUserList] = useState([]);
  const [userCompatibility, setUserCompatibility] = useState({});

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await axios.get('/api/check-auth');
        if (!response.data.isAuthenticated) {
          navigate('/login');
          return;
        } else {
          const count = await getFollowingsNumber();
          setFollowingsCount(count);
        }
        
        const fetchProfile = async () => {
          try {
            const response = await getProfile();
            setProfileData(response.data);
            setEditForm({
              pseudo: response.data.pseudo,
              bio: response.data.bio || '',
              badges: response.data.badges || [],
              followers: response.data.follower || [],
            });
            setPreviewUrl(response.data.avatar_url || '');
          } catch (error) {
            console.error('Error fetching profile:', error);
          }
        };

        fetchProfile();
      } catch (error) {
        console.error('Error checking auth:', error);
        navigate('/login');
      }
    };

    checkAuth();


 




  }, [navigate]);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) { // 5MB en bytes
        setError('File size must be less than 5MB');
        return;
      }
      setAvatarFile(file);
      setPreviewUrl(URL.createObjectURL(file));
      setError('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('pseudo', editForm.pseudo);
      formData.append('bio', editForm.bio);
      if (avatarFile) {
        formData.append('avatar', avatarFile);
      }

      const response = await axios.post('/api/update-profile', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProfileData({ 
        ...profileData, 
        ...editForm,
        avatar_url: response.data.avatar_url || profileData.avatar_url 
      });
      setIsEditing(false);
      setError('');
    } catch (error) {
      console.error('Error updating profile:', error);
      setError(error.response?.data?.error || 'Error updating profile');
    }
  };

  const handleLogout = async () => {
    try {
      await axios.post('/api/logout');
      window.location = '/login';
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  if (!profileData) return <div className="profile-page"><h2>Loading profile...</h2></div>;

  return (
    <div className="profile-page">
      <h2>Mon Profil</h2>
      <button className="logout-button" onClick={handleLogout}>
        <FontAwesomeIcon icon={faSignOutAlt} /> Logout
      </button>
      {!isEditing ? (
        <>
          <div className="profile-header">
            <img 
              src={profileData.avatar_url || 'https://via.placeholder.com/150'} 
              alt="Profile" 
              className="profile-avatar"
            />
            <h2>{profileData.pseudo}</h2>
            <button className="edit-button" onClick={() => setIsEditing(true)}>
              Edit Profile
            </button>
          </div>
          
<div className="manage-follows-container">
        <Link to="/my-profile-friends" className="manage-follows-button">
          <FaUserFriends /> Manage My {followingsCount} Followed Accounts
        </Link>
      </div>

          <div className="profile-nav-buttons">
            <button className="nav-button" onClick={() => navigate('/my-list')}>
              <FontAwesomeIcon icon={faFilm} />
              <span>My List</span>
            </button>
            <button className="nav-button" onClick={() => navigate('/recommendations')}>
              <FontAwesomeIcon icon={faWandMagicSparkles} />
              <span>My Reco</span>
            </button>
          </div>

          <div className="profile-stats">
          <div className="profile-stats">
            <div className="stat-item">
              <span className="stat-value">{profileData.rated_movie_count}</span>
              <span className="stat-label">Movies</span>
            </div>
            <div className="stat-item">
              <span className="stat-value">{profileData.rated_tv_count}</span>
              <span className="stat-label">TV Shows</span>
            </div>
            <div className="stat-item">
              <span className="stat-value">{profileData.badges_number}</span>
              <span className="stat-label">Badges</span>
            </div>
          </div>

          </div>
          <div className="profile-bio">
            <h3>About Me</h3>
            <p>{profileData.bio || 'No bio yet'}</p>
          </div>

          <MyBadges badges={profileData?.badges} />


          <div className="profile-bio">

          {profileData.updated_profile_notification > 0 && (
                <span className="notification-count">{profileData.updated_profile_notification}</span>
              )}


            <h3>Profile Analysis</h3>
            <ReactMarkdown skipHtml={true} breaks={true}>
              {profileData.profile || 'No profile analysis available'}
            </ReactMarkdown>
          </div>
        </>
      ) : (
        <form onSubmit={handleSubmit} className="profile-edit-form">
          <div className="form-group avatar-upload">
            <label className="avatar-label">
              <img 
                src={previewUrl || 'https://via.placeholder.com/150'} 
                alt="Profile" 
                className="profile-avatar"
              />
              <input
                type="file"
                accept="image/*"
                onChange={handleAvatarChange}
                className="hidden"
              />
              <span className="upload-overlay">Change Avatar</span>
            </label>
            {error && <div className="error-message">{error}</div>}
          </div>
          
          <div className="form-group">
            <label>Pseudo</label>
            <input
              type="text"
              value={editForm.pseudo}
              onChange={(e) => setEditForm({ ...editForm, pseudo: e.target.value })}
              required
            />
          </div>
          
          <div className="form-group">
            <label>Bio</label>
            <textarea
              value={editForm.bio}
              onChange={(e) => setEditForm({ ...editForm, bio: e.target.value })}
              rows="4"
            />
          </div>

          <div className="form-buttons">
            <button type="submit">Save</button>
            <button type="button" onClick={() => {
              setIsEditing(false);
              setError('');
              setPreviewUrl(profileData.avatar_url || '');
            }}>Cancel</button>
          </div>
        </form>
      )}

     




    </div>
  );
}

export default MyProfile;