import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import Feed from './components/Feed';
import MyList from './components/MyList';
import Search from './components/Search';
import MyProfile from './components/MyProfile';
import Login from './components/Login';
import MovieDetails from './components/MovieDetails';
import BottomNavBar from './components/BottomNavBar';
import MyReco from './components/MyReco';
import Register from './components/Register';
import UserProfile from './components/UserProfile';
import TinderSwap from './components/TinderSwap';
import Home from './components/Home';
import MyProfileFriends from './components/MyProfileFriends';
import MyFilmotheque from './components/MyFilmotheque';
import './App.css';


function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/" element={<Home />} />
            <Route path="/social" element={<Feed />} />
            <Route path="/my-list" element={<MyList />} />
            <Route path="/search" element={<Search />} />
            <Route path="/my-profile" element={<MyProfile />} />
            <Route path="/my-profile-friends" element={<MyProfileFriends />} />
            <Route path="/:mediaType/:id" element={<MovieDetails />} />
            <Route path="/recommendations" element={<MyReco />} />
            <Route path="/user/:userId" element={<UserProfile />} />
            <Route path="/tinder" element={<TinderSwap />} />
            <Route path="/my-filmotheque" element={<MyFilmotheque />} />
          </Routes>
          <BottomNavBar />

        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
