// src/components/BottomNavBar.js
import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaSearch, FaList, FaUser, FaMagic } from 'react-icons/fa';
import './BottomNavBar.css'; // Nous allons créer ce fichier CSS

const BottomNavBar = () => {
  return (
    <nav className="bottom-nav">
      <Link to="/" className="nav-item">
        <FaHome />
        <span>Home</span>
      </Link>
      <Link to="/search" className="nav-item">
        <FaSearch />
        <span>Search</span>
      </Link>
      <Link to="/my-list" className="nav-item">
        <FaList />
        <span>My List</span>
      </Link>
      <Link to="/recommendations" className="nav-item">
        <FaMagic />
        <span>Reco</span>
      </Link>
      <Link to="/my-profile" className="nav-item">
        <FaUser />
        <span>Profile</span>
      </Link>
    </nav>
  );
};

export default BottomNavBar;