import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaFilm, FaSearch, FaList, FaUser, FaHeart, FaUsers } from 'react-icons/fa';
import './Home.css';

function Home() {
  const navigate = useNavigate();

  const sections = [
    {
      title: "Score",
      description: "Swipe to score movies and refine your profile",
      icon: <FaFilm />,
      path: "/tinder",
      color: "#FF6B6B"
    },
    {
      title: "Social",
      description: "Discover your friends' latest rankings",
      icon: <FaUsers />,
      path: "/social",
      color: "#96CEB4"
    },
    {
      title: "Search",
      description: "Search for movies and series by Title or Description",
      icon: <FaSearch />,
      path: "/search",
      color: "#4ECDC4"
    },
    {
      title: "My List",
      description: "Manage your personnal To-Watch list",
      icon: <FaList />,
      path: "/my-list",
      color: "#45B7D1"
    },

    {
      title: "Recos",
      description: "Discover your recommendations tailored to your profile",
      icon: <FaHeart />,
      path: "/recommendations",
      color: "#D4A5A5"
    },
    {
      title: "My Profil",
      description: "Discover your cinematic personality",
      icon: <FaUser />,
      path: "/my-profile",
      color: "#9B59B6"
    }
  ];

  return (
    <div className="home-container">
      <div className="home-header">
        <h1>CinemApp</h1>
        <div className="tmdb-logo">
          <img 
            src="https://www.themoviedb.org/assets/2/v4/logos/v2/blue_square_2-d537fb228cf3ded904ef09b136fe3fec72548ebc1fea3fbbd1ad9e36364db38b.svg" 
            alt="TMDB Logo" 
          />
        </div>
      </div>
      
      <div className="sections-grid">
        {sections.map((section, index) => (
          <div 
            key={index}
            className="section-card"
            style={{"--card-color": section.color}}
            onClick={() => navigate(section.path)}
          >
            <div className="card-icon">
              {section.icon}
            </div>
            <h2>{section.title}</h2>
            <p>{section.description}</p>
          </div>
        ))}

        <br />
      </div>
    </div>
  );
}

export default Home;
