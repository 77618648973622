import React, { useState, useEffect } from 'react';
import { getMovieDetails, getCasting, getStreamingPlatforms } from '../utils/api';
import './FilmDetails.css';

const FilmDetails = ({ mediaType, id }) => {
  const [movieDetails, setMovieDetails] = useState(null);
  const [castData, setCastData] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        setLoading(true);
        const [details, cast, streamingPlatforms] = await Promise.all([
          getMovieDetails(mediaType, id),
          getCasting(mediaType, id),
          getStreamingPlatforms(mediaType, id)
        ]);
        
        setMovieDetails(details);
        setPlatforms(streamingPlatforms);
        setCastData(cast);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (mediaType && id) {
      fetchDetails();
    }
  }, [mediaType, id]);

  if (loading) {
    return <div className="loading">Chargement...</div>;
  }

  if (error) {
    return <div className="error">Erreur: {error}</div>;
  }

  if (!movieDetails) {
    return <div className="error">Aucune information disponible</div>;
  }

  return (
    <div className="film-details">
      <div className="summary-details">
      <h3>Summary</h3>
        <p>{movieDetails.overview || 'Non disponible'}</p>
        <p><strong>Release: </strong> {movieDetails.release_date || movieDetails.first_air_date || 'Non -'} - {movieDetails.runtime || movieDetails.episode_run_time?.[0] || '-'} minutes</p>
      </div>

      <div className="cast-section">
        <h3>Casting</h3>
        <div className="cast-grid">
          {castData.map((actor) => (
            <div key={actor.id} className="cast-item">
              <img 
                src={actor.profile_path ? 
                  `https://image.tmdb.org/t/p/w185${actor.profile_path}` : 
                  'https://via.placeholder.com/185x278?text=No+Image'
                } 
                alt={actor.name}
              />
              <div className="cast-info">
                <span className="actor-name">{actor.name}</span>
                <span className="character-name">{actor.character}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
          <h3>Platforms</h3>
      <div className="platforms-list">
        {platforms.length > 0 ? (
          platforms.map((platform, index) => (
            <div key={index} className="platform-item">
              <img src={platform.logo} alt={platform.name} />
              <span>{platform.name}</span>
            </div>
          ))
        ) : (
          <p>Non disponible en streaming pour le moment</p>
        )}
      </div>
    </div>
  );
};

export default FilmDetails;